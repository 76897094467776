import { AxiosResponse } from "axios";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { JetBrainLicense } from "../../models/newLicenses";

export const jetBrainsLicenses = (API: APIType, URL: string) => {
  const licenseURL = URL + 'jetBrains';

  const options = {
    getAll: async (params : any) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: licenseURL,
        param : params
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {
        return response.data as JetBrainLicense[];
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      });
    },
    getById: async (id: string) => {
        throw new Error('getById not yet implemented');
    }
  }
  return options;
}
