import { Badge } from "@chakra-ui/react";


const EnvironmentBadge = () => {
    const environment = process.env["REACT_APP_ENVIRONMENT"];

    if (!environment) return null;

    const badgeColor = environment === 'staging' ? 'red.500' : 'green.500';
    
    return (
    <Badge bg={badgeColor} p={1} borderRadius={4} textAlign="center" position="absolute" top={4} left={4}>
        {environment === 'staging' ? 'Staging' : 'Development'}
    </Badge>
    );
}
  
  

export default EnvironmentBadge;