import { ButtonGroup, Heading, IconButton, Stack, Tag, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { InitialTableState, ColumnDef } from "@tanstack/react-table";
import { TanstackTable } from "../../components/TanstackTable";
import { Model } from "../../models/manufacturer";
import { Computer } from "../../models/computer";

type UserComputerProps = {
  email: string,
  computers: Computer[]
}

export const UserComputer = ({email, computers}: UserComputerProps) => {

  const navigate  = useNavigate();
  const [tableData, setTableData] = useState<Computer[]>([]);
  const location = useLocation()

  useEffect(() => {
    setTableData(computers)
  }, [computers])

  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
    columnVisibility: {
      'cardId': false
    }
  }

  const buttonGroup = (id: number) => {
    return(
      <ButtonGroup variant="ghost">
        <Tooltip label="Return">
          <IconButton
            aria-label="return"
            icon={<KeyboardReturnIcon />}
            onClick={(event) => {
              event.stopPropagation();
              navigate(`computer/${id}/return`, { state: { previousLocation: location.pathname} })}}
          />
        </Tooltip>
      </ButtonGroup>
    )
  }

  const statusTags = (status: number | undefined) => {
    if (status != undefined) {
      switch (status) {
        case 0:
          return <Tag colorScheme='red'>Owned</Tag>
        case 1:
          return <Tag colorScheme='orange'>Rented</Tag>
        default:
          throw new Error("Invalid status")
      }
    }else{
      //case rentStatus.AVAILABLE:
      return <Tag colorScheme='green'>Available</Tag>
    }
  }

  const modelTags = (model: Model) => {
    const colorSchemes = ["red", "orange", "yellow", "green", "teal", "blue", "cyan", "purple", "pink", "linkedin", "facebook", "messenger", "whatsapp", "twitter", "telegram"]
    if (model) {
      const colorScheme = +model.id.toString().slice(-1)
      return <Tag colorScheme={colorSchemes[colorScheme]}>{model.name}</Tag>
    }
  }

  const onRowClick = (item: Computer) => {
    navigate(`computer/` + item.id, { state: { previousLocation: location.pathname } })
  }

  const columns: ColumnDef<Computer>[] = [
    {
      id: 'model',
      accessorFn: row => row.model,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Model',
      cell: (info) => modelTags(info.getValue() as Model),
    },
    {
      id: 'status',
      accessorFn: row => row.rentStatus?.status ?? 3,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Status',
      cell: info => statusTags(info.row.original.rentStatus?.status),
    },
    {
      id: 'actions',
      header: 'Actions',
      size: 10,
      enableHiding: false,
      maxSize: 10,
      cell: info => buttonGroup(info.row.original.id),
      meta: {
        isNumeric: true
      }
    },
  ]

  return (
    <Stack spacing='4'>
      <Heading marginLeft='4' size='lg'>Computers</Heading>
      <TanstackTable
        columns={columns}
        data={tableData}
        initialTableState={initialTableState}
        onRowClick={(item: Computer) => onRowClick(item)}
        onSortingChange={(newSorting) => console.log(newSorting)}
        isLoading={false}       
        storageKey='userComputerTableTableVisibility'/>
    </Stack>
  )
}
