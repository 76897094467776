import React from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Spinner, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from "react-router-dom";
import { useFetch } from '../../contexts/FetchContext';
import ResponseHandler, { Responses } from '../../components/Helpers/ResponseHandler';


export const InvalidateLicense = () => {
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const navigate = useNavigate();

    const toast = useToast();

    const { tableName, tableId } = useParams();

    const { licenseIt } = useFetch();


    const onClose = () => {
        navigate(-1);
    };

    const invalidateLicense = (tableId: string) => {
        licenseIt.invalidateLicense(tableId)
        .then(response => {
            ResponseHandler(`Sucessfully invalidated license 🤘`, Responses.Success, toast);
            onClose();
        }).catch(error => {
            ResponseHandler("Could not invalidate license: "+error, Responses.Error, toast);
            onClose();
        })
    }
    
    return (
        tableName && tableId ? (
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={true}
                isCentered
            >
                <AlertDialogOverlay/>
                <AlertDialogContent>
                    <AlertDialogHeader>Invalidate {tableName}?</AlertDialogHeader>
                    <AlertDialogCloseButton/>
                    
                    <AlertDialogBody>
                        Are you sure you want to invalidate {tableName}?
                    </AlertDialogBody>
    
                    <AlertDialogFooter>
                        <Button colorScheme='grey' variant='ghost' ref={cancelRef} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' ml={3} onClick={() => invalidateLicense(tableId)}>Invalidate</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        ) : (
            <Spinner/>
        )
    )
}

