import { IconButton, HStack, Heading, ModalHeader, Tag, Text, Tooltip, useColorModeValue, VStack, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Modal, Box } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Item } from "../models/item"
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PersonIcon from '@mui/icons-material/Person';
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";

type ItemModalProps = {
  headerLine1?: string;
  headerLine2: string;
  item: Item;
  children?: JSX.Element;
  onClose: () => void;
}

export const ItemModal = (props: ItemModalProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const ActionGroup = () => {
    const Actions = [
      ...props.item.rentStatus ? [
        {
          name: 'Return',
          action: <IconButton
            key='return'
            icon={<KeyboardReturnIcon />}
            mr={3}
            onClick={() => navigate('return', { state: { previousLocation: location.pathname } })}
            aria-label={"Return"}
          />
        }
      ] : [
        {
          namoxoe: 'Rent',
          action: <IconButton
            key='rent'
            icon={<PersonIcon />}
            mr={3}
            onClick={() => navigate('rent', { state: { previousLocation: location.pathname } })}
            aria-label={"Rent"}
          />
        }
      ],
      {
        name: 'Edit',
        action: <IconButton
          key='edit'
          icon={<EditIcon />}
          mr={3}
          onClick={() => navigate(`edit`, { state: { previousLocation: location.pathname } })}
          aria-label={"Edit"}
        />
      },
      {
        name: 'Delete',
        action: <IconButton
          key='delete'
          icon={<DeleteIcon />}
          mr={3}
          onClick={() => navigate(`delete`, { state: { previousLocation: location.pathname } })}
          aria-label={"Delete"}
        />
      }
    ]

    return (
      <HStack spacing={1} align='flex-start'>
        {Actions.map((action) => {
          return <Tooltip label={action.name}>{action.action}</Tooltip>
        })}
      </HStack>
    )
  }

  const headerBg = useColorModeValue('gray.100', 'gray.900');
  const botyBg = useColorModeValue('white', 'gray.800');

  const Header = () => {
    return (
      < ModalHeader backgroundColor={headerBg} borderTopRadius={6} >
        <VStack align='flex-start'>
          {props.headerLine1 &&
            <Text size='md' marginTop={5} colorScheme='linkedin'>{props.headerLine1}</Text>
          }

          <Heading size='xl' marginTop={0}>{props.headerLine2}</Heading>
          <HStack justifyContent='space-between' w='100%'>
            {props.item.rentStatus ?
              props.item.rentStatus.status == 1 ?
                <HStack
                  fontWeight='semibold'
                  letterSpacing='wide'
                  fontSize='sm'
                >
                  <Tag colorScheme='orange'>RENTED</Tag>
                  <Text>&bull; {props.item.rentStatus?.rentedBy} &bull; {props.item.rentStatus?.from.toString().split("T")[0]}</Text>
                </HStack>
                :
                <HStack
                  fontWeight='semibold'
                  letterSpacing='wide'
                  fontSize='sm'
                >
                  <Tag colorScheme='red'>OWNED</Tag>
                  <Text>&bull; {props.item.rentStatus?.rentedBy} &bull; {props.item.rentStatus?.from.toString().split("T")[0]}</Text>
                </HStack>
              :
              <Tag colorScheme='green'>AVAILABLE</Tag>
            }
            <ActionGroup />
          </HStack>
        </VStack>
      </ModalHeader >
    )
  }

  const RentHistory = () => {
    return (
      <>
        {props.item.rentHistory && props.item.rentHistory.length > 0 &&
          <VStack align='flex-start' overflow='hidden' w='100%' p={6}>
            <Heading size='md'>Previous History</Heading>
            <HStack align='flex-start' spacing={2} overflowX='scroll' w='100%' pr={6} paddingBottom={4}>
              {props.item?.rentHistory.map((rent) => {
                const fromDate = new Date(rent.from);
                const toDate = new Date(rent.to);
                return (
                  <VStack align='flex-start' whiteSpace='nowrap' shadow='sm' bg={headerBg} p={4} borderRadius={10} spacing={0}>
                    <Heading size='sm'>{rent.rentedBy}</Heading>
                    <Text size='sm'>{
                      fromDate.getDate()}.{fromDate.getMonth() + 1}.{fromDate.getFullYear()} - {toDate.getDate()}.{toDate.getMonth() + 1}.{toDate.getFullYear()
                      }</Text>
                    <Box w='100%' overflow='scroll'>
                      <Text size='sm' w='50px'>
                        {rent.note}
                      </Text>
                    </Box>
                  </VStack>
                )
              })}
            </HStack>
          </VStack>
        }
      </>
    )
  }

  return (
    <>
      <Modal
        isCentered={true}
        isOpen={true}
        onClose={props.onClose}
        size='3xl'
        autoFocus={false}
      >
        <ModalOverlay
          backdropFilter='auto'
          backdropBlur='5px'
          onClick={() => navigate('/')}
        />
        <ModalContent backgroundColor={botyBg}>
          <Header />
          <ModalBody p={6}>
            {props.children}
          </ModalBody>
          <RentHistory />
          <ModalFooter>
            <Button mr={3} onClick={props.onClose} variant='outline'>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
