import React, {useContext } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from "react-router-dom";
import ResponseHandler, {Responses} from './ResponseHandler';
import { dotNetError } from '../../models/dotnetDataModels';
import { UserContext } from '../../auth/Auth';
import { useFetch } from '../../contexts/FetchContext';

export const ReturnModal = () => {
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const user = useContext(UserContext);

    const navigate = useNavigate();

    const toast = useToast();

    const { id } = useParams();

    const {backend} = useFetch();

    const onClose = () => {
        navigate(-1);
    };
    
    const handleReturn = () => {
        if (user) {
            backend.items.return(parseInt(id!))
                .then(() => {
                    ResponseHandler(`Successfully returned item 🤘`, Responses.Success, toast);
                    onClose();
                })
                .catch((error: dotNetError) => {
                    ResponseHandler(error.response.data.detail, Responses.Error, toast);
                });
        }
    };
    
    return (
        <div>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={true}
                isCentered
            >
                <AlertDialogOverlay/>
                
                <AlertDialogContent>
                    <AlertDialogHeader>Return Item?</AlertDialogHeader>
                    <AlertDialogCloseButton/>
                    
                    <AlertDialogBody>
                        Are you sure you want to return this item?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme='grey' variant='ghost' ref={cancelRef} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' ml={3} onClick={handleReturn}>Return</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    )
}

