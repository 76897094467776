import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Tag,
  Spinner,
} from "@chakra-ui/react";
import { EquipitUser } from "../models/user";
import { useFetch } from "../contexts/FetchContext";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

type UserTagProps = {
  email: string;
  enablePopover?: boolean; 
};

export const UserTag = ({ email, enablePopover = true }: UserTagProps) => {
  const { backend } = useFetch();
  const [user, setUser] = useState<EquipitUser | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && !user && !error) {
      backend
        .users
        .getAll(email)
        .then((users: EquipitUser[]) => {
          const foundUser = users.find(
            (userItem) => userItem.displayName === email
          );
          if (foundUser) {
            setUser(foundUser);
          } else {
            setUser({
              displayName: "Unknown",
              company: "Unknown",
              email: email,
            } as EquipitUser);
          }
        })
        .catch((err: any) => {
          setError(err.message || "An error occurred");
        });
    }
  }, [email, isOpen, user, error, backend.users]);

  const PopoverContentElement = useMemo(() => {
    if (user) {
      return (
        <>
          <PopoverHeader fontWeight="bold">{user.email}</PopoverHeader>
          <PopoverBody>{user.company}</PopoverBody>
        </>
      );
    } else if (error) {
      return (
        <>
          <PopoverHeader fontWeight="bold">Error</PopoverHeader>
          <PopoverBody>
            {error}
            <Spinner size="sm" ml={2} />
          </PopoverBody>
        </>
      );
    } else {
      return (
        <>
          <PopoverHeader fontWeight="bold">Loading...</PopoverHeader>
          <PopoverBody>
            <Spinner size="sm" />
          </PopoverBody>
        </>
      );
    }
  }, [user, error]);

  const handleMouseOver = () => {
    if (enablePopover) {
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (enablePopover) {
      setIsOpen(false);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/user/${email}`);
  };

  const TagContent = (
    <Tag
      colorScheme="blue"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      cursor="pointer" // Always show pointer cursor
    >
      {email}
    </Tag>
  );

  if (enablePopover) {
    return (
      <Popover isOpen={isOpen} placement="bottom" gutter={8}>
        <PopoverTrigger>{TagContent}</PopoverTrigger>
        <PopoverContent>
          {PopoverContentElement}
        </PopoverContent>
      </Popover>
    );
  } else {
    return TagContent;
  }
};