import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import './App.css';
import { createRoot } from 'react-dom/client';
import theme from './contexts/theme'
import { App } from './App';
import { AuthProvider } from './auth/AuthProvider';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { AppContextProvider } from './contexts/AppContext';


console.log(
  `Application is starting up with env variables:
  Client ID = ${process.env["REACT_APP_CLIENT_ID"]}
  Backend URL = ${process.env["REACT_APP_EQUIPIT_BACKEND"]}
  Redirect URI = ${process.env["REACT_APP_REDIRECT_URI"]}
  Tenant ID = ${process.env["REACT_APP_TENANT_ID"]}
  `
)

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
        <AuthProvider>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </AuthProvider>
    </ChakraProvider>
  </>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
