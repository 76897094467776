import { Button, ButtonGroup, Heading, Stack, Tag } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, } from "react-router-dom";
import { InitialTableState, ColumnDef } from "@tanstack/react-table";
import { TanstackTable } from "../../components/TanstackTable";
import { PlusSquareIcon, MinusIcon } from "@chakra-ui/icons";
import { License, LicenseGroup, licenseStatusEnum } from "../../models/newLicenses";
import { useFetch } from "../../contexts/FetchContext";

type UserLicensesProps = {
  email: string,
  licenses: License[]
}

export const UserLicenses = ({email, licenses}: UserLicensesProps) => {

  const navigate  = useNavigate();
  const [tableData, setTableData] = useState<License[]>([]);
  const [licenseGroups, setLicenseGroups] = useState<LicenseGroup[]>([]);
  const location = useLocation()
  const { backend } = useFetch()

  useEffect(() => {
    backend.licenseGroups.getAll().then((licenseGroups: LicenseGroup[]) => {
      setLicenseGroups(licenseGroups)
    }).catch((err: any) => {
      console.log(err)
    })
    setTableData(licenses)
  }, [licenses])

  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
    columnVisibility: {
      'cardId': false
    }
  }

  const statusTags = (status: licenseStatusEnum) => {
    switch (status) {
      case licenseStatusEnum.ACTIVE:
        return <Tag colorScheme='blue'>Active</Tag>
      case licenseStatusEnum.DELETED:
        return <Tag colorScheme='orange'>Deleted</Tag>
      case licenseStatusEnum.DELETED_IN_AD:
        return <Tag colorScheme='red'>Deleted in AD</Tag>
      case licenseStatusEnum.NEW_IN_AD:
        return <Tag colorScheme='green'>New in AD</Tag>
      default:
        throw new Error("Invalid status")
    }
  }

  const getLicenseDisplayName = (licenseGroupKey: string) => {
    const licenseGroup = licenseGroups.find((licenseGroup: LicenseGroup) => licenseGroup.licenseKey === licenseGroupKey)
    if (licenseGroup) {
      return licenseGroup.displayName
    }
    return 'Unknown'
  }

  const buttonGroup = (status: licenseStatusEnum, userId: number) => {
    const actionButton = () => {
      if (status === licenseStatusEnum.NEW_IN_AD) {
        return <Button
          aria-label="add"
          leftIcon={<PlusSquareIcon />}
          onClick={(event) => { event.stopPropagation(); navigate(`license/${userId}/add`, { state: { previousLocation: location.pathname } }) }}>
          Add
        </Button>
      } else if (status === licenseStatusEnum.DELETED_IN_AD) {
        return <Button aria-label="delete" colorScheme='red' leftIcon={<MinusIcon />} onClick={(event) => { event.stopPropagation(); navigate(`${userId}/remove`, { state: { previousLocation: location.pathname } }) }}>Remove</Button>
      }
    }
    return (
      <ButtonGroup variant="ghost">
        {actionButton()}
      </ButtonGroup>
    )
  }

  const columns: ColumnDef<License>[] = [
    {
      id: 'licenseGroup',
      accessorFn: row => row.licenseGroupKey,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'License Group',
      cell: (info) => getLicenseDisplayName(info.row.original.licenseGroupKey)
    },
    {
      id: 'status',
      accessorFn: row => row.licenseStatus,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Status',
      cell: info => statusTags(info.row.original.licenseStatus),
    },
    {
      id: 'actions',
      header: 'Actions',
      size: 10,
      enableHiding: false,
      maxSize: 10,
      cell: info => buttonGroup(info.row.original.licenseStatus, info.row.original.id),
      meta: {
        isNumeric: true
      }
    },
  ]

  return (
    <Stack spacing='4'>
      <Heading marginLeft='4' size='lg'>Licenses</Heading>
      <TanstackTable
        columns={columns}
        data={tableData}
        initialTableState={initialTableState}
        onSortingChange={(newSorting) => console.log(newSorting)}
        isLoading={false}       
        storageKey='userLicenseTableTableVisibility'/>
    </Stack>
  )
}
