import React, { useRef } from 'react';
import { Textarea } from '@chakra-ui/react';

interface Props {
  placeholder: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const AutoExpandingTextarea = (props: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'inherit'; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    }
    if (props.onChange)
        props.onChange(e);
  };

  return (
    <Textarea
      placeholder={props.placeholder}
      ref={textareaRef}
      onChange={handleInput}
      maxH="300px"
      resize="none"
    />
  );
};
export default AutoExpandingTextarea;
