import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Stack, useColorModeValue, Wrap } from '@chakra-ui/react';
import { ColumnDef, InitialTableState, SortingState } from '@tanstack/react-table';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TanstackTable } from '../components/TanstackTable';
import { MultiSelectOption } from '../components/MultiSelect';
import { AppContext } from '../contexts/AppContext';

interface TableDataProps<T> {
  title: string;
  fetchItems: (params: any) => Promise<T[]>;
  columns: ColumnDef<T>[];
  initialSortColumn: string;
  sortDesc: boolean;
  statusOptions: MultiSelectOption[];
  additionalFilters?: React.ReactNode;
  onRowClick: (item: T) => void;
  buttonGroup: (item: T) => React.ReactNode;
  searchParams: URLSearchParams;
}

export function TableData<T>({
  title,
  fetchItems,
  columns,
  initialSortColumn,
  sortDesc,
  statusOptions,
  additionalFilters,
  onRowClick,
  buttonGroup,
  searchParams
}: TableDataProps<T>) {
  const [items, setItems] = useState<T[]>([]);
  const app = useContext(AppContext);
  const location = useLocation();
  const bodyBg = useColorModeValue('white', "gray.900");
  const loader = useRef(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const prevLocationRef = useRef(location.pathname);
  const [hasMore, setHasMore] = useState(true);

  const [page, setPage] = useState(1);
  const PAGESIZE = 20;

  const getParamsFromSearchParams = useCallback(() => {
    const params: { [key: string]: any } = {};
    searchParams.forEach((value, key) => {
      params[key] = value.split(',');
    });

    params.Sort = initialSortColumn;
    params.Desc = sortDesc;

    return params;
  }, [searchParams, initialSortColumn, sortDesc]);

  const [params, setParams] = useState<{ [x: string]: any }>(getParamsFromSearchParams);

  useEffect(() => {
    if (location.pathname === '/' + title.toLowerCase() && prevLocationRef.current === location.pathname) {
      setParams(getParamsFromSearchParams());
      setPage(1);
    }
  }, [searchParams, getParamsFromSearchParams]);

  useEffect(() => {
    prevLocationRef.current = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false)
    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/' + title.toLowerCase() && !isInitialRender) {
      refetch();
    }
  }, [params, page]);

  useEffect(() => {
    if (location.pathname === '/' + title.toLowerCase() && !isInitialRender) {
      reloadAll();
    }

  }, [location.pathname]);

  useEffect(() => {
    app.setTitle(title);
  }, [app, title]);

  const refetch = useCallback(() => {
    fetchItems({ ...params, Page: page, PageSize: PAGESIZE })
      .then((newItems: T[]) => {
        setItems(prevItems => page === 1 ? newItems : [...prevItems, ...newItems]);
        setHasMore(newItems.length === PAGESIZE);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
        setHasMore(false);
      });
  }, [fetchItems, params, page, PAGESIZE]);

  const reloadAll = useCallback(() => {
    fetchItems({ ...params, Page: 1, PageSize: page * PAGESIZE })
      .then((newItems: T[]) => {
        setItems(newItems);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  }, [fetchItems, params, page, PAGESIZE]);

  const updateParams = useCallback((key: string, value: any) => {
    setParams(prevParams => ({ ...prevParams, [key]: value }));
    setPage(1);
    setHasMore(true);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prevPage => prevPage + 1);
        }
      },
      { root: null, rootMargin: '20px', threshold: 1.0 }
    );

    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [hasMore]);

  const filterContainer = useMemo(() => (
    <Wrap bg={bodyBg} borderRadius={6} shadow='md' p={4} w="100%" spacing={4} marginBottom={4} align="flex-end">
      {additionalFilters}
    </Wrap>
  ), [bodyBg, additionalFilters]);

  const enhancedColumns: ColumnDef<T>[] = useMemo(() => [
    ...columns,
    {
      id: 'actions',
      header: 'Actions',
      cell: info => buttonGroup(info.row.original),
      meta: { isNumeric: true }
    },
  ], [columns, buttonGroup]);

  const initialTableState: InitialTableState = {
    sorting: [{ id: params.Sort || initialSortColumn, desc: params.Desc ?? sortDesc }],
  };

  return (
    <>
      <Stack direction='row' alignItems='center'>
        {filterContainer}
      </Stack>
      <TanstackTable
        columns={enhancedColumns}
        storageKey={`${title}TableVisibility`}
        data={items}
        initialTableState={initialTableState}
        onRowClick={onRowClick}
        onSortingChange={(sorting: SortingState) => {
          updateParams('Sort', sorting[0]?.id || initialSortColumn);
          updateParams('Desc', sorting[0]?.desc ?? sortDesc);
        }}
        isLoading={!items}
      />
      <div ref={loader} />
    </>
  );
}
