import React from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Spinner, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from "react-router-dom";
import { useFetch } from '../../contexts/FetchContext';
import ResponseHandler, { Responses } from '../../components/Helpers/ResponseHandler';

export const ApproveLicense = () => {
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const navigate = useNavigate();

    const toast = useToast();

    const { userId } = useParams();

    const { backend } = useFetch();


    const onClose = () => {
        navigate(-1);
    };

    const addLicense = (userId: string) => {
        backend.licenses.approve(parseInt(userId))
        .then(response => {
            ResponseHandler(`Sucessfully added license 🤘`, Responses.Success, toast);
            onClose();
        })
        .catch(error => {
            ResponseHandler("Could not fetch licenses: "+error, Responses.Error, toast);
            onClose();
        })
    }

    return (
        userId ? (
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={true}
                isCentered
            >
                <AlertDialogOverlay/>
                <AlertDialogContent>
                    <AlertDialogHeader>Approve license?</AlertDialogHeader>
                    <AlertDialogCloseButton/>

                    <AlertDialogBody>
                        Are you sure you want to approve this license?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme='grey' variant='ghost' ref={cancelRef} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' ml={3} onClick={() => addLicense(userId)}>Approve</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        ) : (
            <Spinner/>
        )
    )
}

