import { Button, FormLabel, Heading, Input } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from '@tanstack/react-form'
import { FormControl } from '@chakra-ui/react'
import { useFetch } from "../../contexts/FetchContext";
import { FormModal } from "../../components/Helpers/FormModal";

export const CreateLicense = () => {
  const navigate = useNavigate();
  const { licenseIt } = useFetch();

  const onClose = () => {
    navigate(-1);
};

  const form = useForm({
    defaultValues: {
      displayName: '',
      licenseCode: '',
    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit")) {
        const displayName = values.displayName!
        const licenseCode = values.licenseCode!
        licenseIt.createLicense(
          displayName, licenseCode
        ).then((response) => {
          onClose();
        }).catch((error) => {
          console.log(error)
        });
      }
    }
  })

  const FormFields = [
    (
      <form.Field 
        name="displayName" 
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Display name is required'
            : undefined
        }}
        children={field => {
          return(
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Display Name</FormLabel>
              <Input variant='filled' required name={field.name} value={field.state.value} onBlur={field.handleBlur} onChange={(e) => field.handleChange(e.target.value)} placeholder="E.g. Docker"/>
            </FormControl>
          )
        }}
      />
    ),(
      <form.Field 
        name="licenseCode" 
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Display name is required'
            : undefined
        }}
        children={field => {
          return(
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>License Code</FormLabel>
              <Input variant='filled' required name={field.name} value={field.state.value} onBlur={field.handleBlur} onChange={(e) => field.handleChange(e.target.value)} placeholder="E.g. lic-objectnet-docker"/>
            </FormControl>
          )
        }}
      />
    )
  ]

  const SubmitButton = () => {
    return(
      <Button colorScheme='blue' isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
        Create
      </Button>
    )
  }

  const Header = () => {
    return (
      <>
        <Heading size='xl' mt={5}>New License</Heading>
      </>
    )
  }

  return (
    <FormModal
      isLoading={true}
      headerElements={[<Header/>]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton/>}
      onClose={onClose}
    />
  )
}