import { createContext, useContext } from "react";

export enum UserRoleEnum {
  Admin = 'Admin',
  Moderator = 'Moderator',
  Receptionist = 'Receptionist',
  User = 'User',
}

export type UserContextType = {
  email: string,
  name?: string,
  role: UserRoleEnum,
} | null

const UserContext = createContext<UserContextType>(undefined!);

const useUserContext = () => {
  return useContext(UserContext)
}

export { UserContext, useUserContext }