import { Item } from "./item";

export interface Keycard extends Item {
  cardId: string,
  pin: string,
  type: string
}

export const keycardToString = (keycard: Keycard): string => {
  return `Kort ${keycard.cardId.toString().padStart(2, '0')}`;
}
