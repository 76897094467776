import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import { useFetch } from "../../contexts/FetchContext";
import { EquipitUser } from "../../models/user";
import { SearchIcon } from "@chakra-ui/icons";
import { debounce } from "lodash";

export const UserSearch = () => {

  const [userList, setUserList] = useState<EquipitUser[]>([]);

  const navigate = useNavigate();

  const {backend} = useFetch();

  const inputRef = useRef<HTMLInputElement>(null);

  const fetchData = async (query: string) => {
    const res = await backend.users.getAll(query);
    if (res) {
      return res
    }
    return [];
  };

  const debouncedFilter = debounce(async (query: string) => {
    if (!query) return setUserList([]);
    setUserList(await fetchData(query));
  }, 100);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e.target.value);
  };

  const handleChange = (selectedOption: EquipitUser) => {
    inputRef.current!.value = '';
    navigate(`user/${selectedOption.email}`);
  };

  return (
    <AutoComplete openOnFocus onSelectOption={(item) => handleChange(item.item.originalValue)} emptyState={['']}>
      <InputGroup>
        <InputRightElement
          pointerEvents='none'
          children={<SearchIcon/>}
        />
        <AutoCompleteInput
          placeholder="Search for user"
          onChange={handleInputChange}
          ref={inputRef}
        />
      </InputGroup>
      
      <AutoCompleteList>
        {userList.map((user, index) => {
          return(
            <AutoCompleteItem
              key={index}
              value={user}
            >
              {user.displayName}
            </AutoCompleteItem>
          )
        })}
      </AutoCompleteList>
    </AutoComplete>
  )
}