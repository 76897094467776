import { Stack } from "@chakra-ui/react";
import { InitialTableState } from "@tanstack/react-table";
import { useState, useContext, useEffect, useMemo } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { useFetch } from "../contexts/FetchContext";
import { ItemCollection } from "../models/itemCollection";
import { UserComputer } from "./User/UserComputer";
import { UserKeyCards } from "./User/UserKeyCards";
import { UserLicenses } from "./User/UserLicenses";
import { UserContext } from "../auth/Auth";
import { License } from "../models/newLicenses";


export const Equipment = () => {
  const [items, setItems] = useState<ItemCollection>();
  const [licenses, setLicenses] = useState<License[]>();

  const { email } = useContext(UserContext)!;
  const app = useContext(AppContext);
  const {backend} = useFetch();
  app.setTitle("Equipment")
  const location = useLocation()

  useEffect(() => {
    refetch()
  }, [location])

  const refetch = () => {
    if (email){
      console.log(`Fetching user items ${email}`);
      backend.items.getByEmail(email).then((data) => {
        if (data) {
          setItems(data);
        }
      }).catch((err) => {
        console.log(err)
      })
      backend.licenses.getAll({UserEmails: [email]}).then((licenses: License[]) => {
        if (licenses.length > 0) {
          setLicenses(licenses);
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  useEffect(() => {
    if (location.pathname === '/user') refetch()
  }, [location])

  const isLoading = useMemo(() => {
    return items ? false : true
  }, [items])

  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
    columnVisibility: {
      'cardId': false
    }
  }

  return (
    <Stack spacing='4'>
      {email && (
        <>
          {(items?.computers?.length || 0) > 0 && (
            <UserComputer email={email} computers={items!.computers!}/>
          )}
          {(items?.keyCards?.length || 0) > 0 && (
            <UserKeyCards email={email} keycards={items!.keyCards!}/>
          )}
          {(licenses?.length || 0) > 0 && (
            <UserLicenses email={email} licenses={licenses!}/>
          )}
        </>
      )}
      <Outlet />
    </Stack>
  )
}
