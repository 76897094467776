export enum licenseStatusEnum {
  NEW_IN_AD,
  DELETED_IN_AD,
  DELETED,
  ACTIVE,
  UNASSIGNED,
}

export interface LicenseGroup {
  id: number,
  displayName: string,
  licenseKey: string,
  active: boolean,
}

export interface License {
  id: number,
  licenseStatus: licenseStatusEnum,
  userEmail: string,
  licenseGroupKey: string,
}

export interface LicenseQuery {
  LicenseStatuses?: licenseStatusEnum[],
  UserEmails?: string[],
  LicenseGroupKeys?: string[],
}

export interface JetBrainLicense {
  id: number,
  licenseStatus: licenseStatusEnum,
  userEmail: string,
  licenseGroupKey: string , 
  productName: string | undefined,
  isValid: boolean | undefined,
  validUntil: string | undefined,
  teamName: string | undefined,
}

