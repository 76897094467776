import React, { useContext, useRef, useMemo, useCallback } from 'react';
import { IconButton, Tag, ButtonGroup, Tooltip, useColorModeValue, Input, Select, Text, VStack, WrapItem, Button } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PersonIcon from '@mui/icons-material/Person';
import { DeleteIcon, EditIcon, RepeatClockIcon } from '@chakra-ui/icons';
import { useFetch } from '../../contexts/FetchContext';
import { UserTag } from '../../components/UserTag';
import { TableData } from '../../components/TableData';
import { Keycard } from '../../models/keycard';
import { MultiSelect } from '../../components/MultiSelect';

export const KeycardTable: React.FC = () => {
  const { backend } = useFetch();
  const navigate = useNavigate();
  const location = useLocation();
  const loader = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();


  const fetchItems = (params: any) => backend.keycards.getAll(params);

  const statusOptions = [
    { key: '0', label: 'Owned', colorScheme: 'red' },
    { key: '1', label: 'Rented', colorScheme: 'orange' },
    { key: '2', label: 'Available', colorScheme: 'green' },
  ];

  const buttonGroup = (keycard: Keycard) => {
    const deleteOrRestoreButton = () => {
      const isDeleted = keycard.deleted && new Date(keycard.deleted) > new Date(0);

      if (!isDeleted) {
        return (
          <Tooltip label="Delete">
            <IconButton
              aria-label="delete"
              icon={<DeleteIcon />}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/keycards/${keycard.id}/delete`, { state: { previousLocation: location.pathname } });
              }}
            />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip label="Restore">
            <IconButton
              aria-label="restore"
              icon={<RepeatClockIcon />}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/keycards/${keycard.id}/restore`, { state: { previousLocation: location.pathname } });
              }}
            />
          </Tooltip>
        );
      }
    };

    const rentButton = () => {
      if (keycard.rentStatus?.status !== undefined) {
        return (
          <Tooltip label="Return">
            <IconButton
              aria-label="return"
              icon={<KeyboardReturnIcon />}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/keycards/${keycard.id}/return`, { state: { previousLocation: location.pathname } });
              }}
            />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip label="Rent">
            <IconButton
              aria-label="rent"
              icon={<PersonIcon />}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/keycards/${keycard.id}/rent`, { state: { previousLocation: location.pathname } });
              }}
            />
          </Tooltip>
        );
      }
    };

    return (
      <ButtonGroup variant="ghost">
        {rentButton()}
        <Tooltip label="Edit">
          <IconButton
            aria-label="edit"
            icon={<EditIcon />}
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/keycards/${keycard.id}/edit`, { state: { previousLocation: location.pathname } });
            }}
          />
        </Tooltip>
        {deleteOrRestoreButton()}
      </ButtonGroup>
    );
  };

  const statusTags = (status: number | undefined) => {
    if (status !== undefined) {
      switch (status) {
        case 0:
          return <Tag colorScheme='red'>Owned</Tag>
        case 1:
          return <Tag colorScheme='orange'>Rented</Tag>
        case 2:
          return <Tag colorScheme='green'>Available</Tag>
        case 3:
          return <Tag colorScheme='purple'>Deleted</Tag>
      }
    }
    return <Tag colorScheme='green'>Available</Tag>
  };

  const onRowClick = useCallback((element: Keycard) => {
    const newPath = `/keycards/${element.id}?${searchParams.toString()}`;
    navigate(newPath, { state: { previousLocation: location.pathname } });
  }, [navigate, location.pathname, searchParams]);

  const columns: ColumnDef<Keycard>[] = [
    {
      id: 'cardId',
      accessorFn: row => `${row.type} ${String(row.cardId).padStart(2, '0')}`,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Type Card ID',
      cell: (info) => info.getValue(),
    },
    {
      id: 'status',
      accessorFn: row => {
        const isDeleted = row.deleted && new Date(row.deleted) > new Date(0);
        return isDeleted ? 3 : (row.rentStatus?.status ?? 2);
      },
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Status',
      cell: info => statusTags(info.getValue() as number),
    },
    {
      id: 'user',
      accessorFn: row => row.rentHistory[0]?.id ?? null,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'User',
      cell: (info) => info.row.original.rentStatus ? UserTag({ email: info.row.original.rentStatus.rentedBy }) : null,
    },
  ];

  const additionalFilters = useMemo(() => (
    <>
      <WrapItem as={VStack} align="left">
        <Text fontWeight="bold">Search</Text>
        <Input
          placeholder="Search for keycards"
          w="300px"
          value={searchParams.get('query') || ''}
          onChange={(e) => {
            const newParams = new URLSearchParams(searchParams);
            if (e.target.value) {
              newParams.set('query', e.target.value);
            } else {
              newParams.delete('query');
            }
            setSearchParams(newParams);
          }}
        />
      </WrapItem>
      <WrapItem as={VStack} align="left">
        <Text fontWeight="bold">Show Deleted</Text>
        <Select
          value={searchParams.get('IncludeDeleted') || 'false'}
          onChange={(e) => {
            const newParams = new URLSearchParams(searchParams);
            if (e.target.value === 'true') {
              newParams.set('IncludeDeleted', 'true');
            } else {
              newParams.delete('IncludeDeleted');
            }
            setSearchParams(newParams);
          }}
        >
          <option value='false'>Do not show deleted</option>
          <option value='true'>Show deleted</option>
        </Select>
      </WrapItem>
      <WrapItem>
        <MultiSelect
          label='Status'
          options={statusOptions}
          onChange={(selected) => {
            const newParams = new URLSearchParams(searchParams);
            const statuses = selected.map(item => item.key);
            if (statuses.length && statuses.length < statusOptions.length) {
              newParams.set('RentStatus', statuses.join(','));
            } else {
              newParams.delete('RentStatus');
            }
            setSearchParams(newParams);
          }}
          value={searchParams.get("RentStatus")?.split(",").map(Number) || []}
        />
      </WrapItem>
      <WrapItem>
        <Button colorScheme='blue' onClick={() => navigate('new')}>Create</Button>
      </WrapItem>
    </>
  ), [searchParams, setSearchParams]);

  return (
    <>
      <TableData<Keycard>
        title="Keycards"
        fetchItems={fetchItems}
        columns={columns}
        initialSortColumn=""
        sortDesc={false}
        statusOptions={statusOptions}
        onRowClick={onRowClick}
        buttonGroup={buttonGroup}
        additionalFilters={additionalFilters}
        searchParams={searchParams}
      />
      <div ref={loader} />
      <Outlet />
    </>
  );
};
