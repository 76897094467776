import { AxiosResponse } from "axios";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { LicenseGroup } from "../../models/newLicenses";

export const licenseGroups = (API: APIType, URL: string) => {
  const licenseGroupURL = URL + 'licensegroup';

  const options = {
    getAll: async (params?: any) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: licenseGroupURL,
        param: params
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {
        return response.data as LicenseGroup[];
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      });
    },
    getByLicenseKey: async (LicenseKey: string) => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${licenseGroupURL}/${LicenseKey}`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as LicenseGroup;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    create: async (licensegroup: LicenseGroup) => {
      const APIParams = {
        method: Method.post,
        needToken: true,
        URL: licenseGroupURL,
        body: JSON.stringify(licensegroup)
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as LicenseGroup;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    update: async (licensegroup: LicenseGroup) => {
      const APIParams = {
        method: Method.put,
        needToken: true,
        URL: licenseGroupURL,
        body: JSON.stringify(licensegroup)
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as LicenseGroup;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    }
  }
  return options;
}
