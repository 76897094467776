import { AxiosResponse } from "axios";
import { Computer } from "../../models/computer";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";

export const computersAPI = (API: APIType, URL: string) => {
  const computerURL = URL + 'computer';

  const options = {
    get: async (params?: any) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: computerURL, 
        param: params
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {
        return response.data as Computer[];
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      });
    },
    getById: async (id: number) => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${computerURL}/${id}`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as Computer;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    create: async (computer: Computer) => {
      const APIParams = {
        method: Method.post,
        needToken: true,
        URL: computerURL,
        body: JSON.stringify(computer)
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as Computer;
      }).catch((error) => {
        console.log(error.response);
        throw new Error(error.response.data.detail);
      })
    },
    update: async (computer: Computer) => {
      const APIParams = {
        method: Method.put,
        needToken: true,
        URL: computerURL,
        body: JSON.stringify(computer)
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as Computer;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    }
  }
  return options;
}