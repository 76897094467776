import { useContext, useEffect, useState } from 'react';
import { Heading, Button, useColorModeValue, Box, Stack, Badge } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../auth/Auth';
import { sideBarLinks } from './NavigationLinks';
import { UserSearch } from '../../pages/User/UserSearch';
import { License } from '../../models/licenses';
import { useFetch } from '../../contexts/FetchContext';
import EnvironmentBadge from '../environments/EnvironmentBadge';

const Sidebar = () => {

  const user = useContext(UserContext);

  const navigate = useNavigate();

  const location = useLocation();

  const { licenseIt } = useFetch()

  const [licenses, setLicenses] = useState<License[]>([])

  useEffect(() => {
    licenseIt.licenses().then((licenses) => {
      setLicenses(licenses)
    }).catch((err) => {
      console.log(err)
    })
  }, [user])

  const bg = useColorModeValue('gray.100', 'gray.900')

  const navButton = (path: string, title: string, index: number) => {
    return(
      <Button key={index} onClick={() => navigate(path)} isActive={decodeURI(location.pathname).startsWith(path)} variant='navButton'>{title}</Button>
    )
  }
  const navList = () => {
    return(
      <>
        <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
          {sideBarLinks.user.map((element, index) => {
            return(
              navButton(element.path, element.title, index)
            )
          })}
        </Stack>
        {user && user.role !== 'User'?(
          <>
            <Heading pl={4} mr={4} size='md'>Admin</Heading>
            <Heading pl={4} mr={4} size='xs'>USERS</Heading>
            <UserSearch/>
            <Heading pl={4} mr={4} size='xs'>POLICY</Heading>
            <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
              {sideBarLinks.admin.policy.map((element, index) => {
                if(element.alloweduser.includes(user.role)){
                  return(
                    navButton(element.path, element.title, index)
                  )
                }
              })}
            </Stack>
            <Heading pl={4} mr={4} size='xs'>EQUIPMENT</Heading>
            <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
              {sideBarLinks.admin.equipment.map((element, index) => {
                if(element.alloweduser.includes(user.role)){
                  return(
                    navButton(element.path, element.title, index)
                  )
                }
              })}
            </Stack>
            <Heading pl={4} mr={4} size='xs'>OTHER</Heading>
            <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
              {sideBarLinks.admin.other.map((element, index) => {
                 if(element.alloweduser.includes(user.role)){
                  return(
                    navButton(element.path, element.title, index)
                  )
                }
              })}
            </Stack>
            <Heading pl={4} mr={4} size='xs'>LICENSES</Heading>
            <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
              {sideBarLinks.admin.licenses.map((element, index) => {
                  if(element.alloweduser.includes(user.role)){
                    if (element.customComponent) {
                      return(
                        element.customComponent
                      )
                    }
                    return(
                      navButton(element.path, element.title, index)
                    )
                  }               
              })}
            </Stack>
          </>
        ):(
          <></>
        )}
      </>
    )
  }


  return (
    <Box bg={bg} shadow='md' p={4} pt={20} h='100%' overflow='auto'>
      <EnvironmentBadge/>
      
      <Heading w='100%' mb={9} textAlign={[ 'center' ]} size={"2xl"}>EquipIt</Heading>

      <Stack direction='column' spacing={3}>
        {navList()}
      </Stack>
    </Box>
  )
}

export default Sidebar;
