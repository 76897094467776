import { ReactNode, createContext, useState } from "react";

type AppContextProps = {
  children: ReactNode
}

export const AppContextProvider = ({children}: AppContextProps) => {
  const [title, setTitle] = useState("THIS IS DEFAULT App");

  return (
    <AppContext.Provider value={{title, setTitle}}>
      {children}
    </AppContext.Provider>
  )
}

export const AppContext = createContext({title: "THIS IS DEFAULT App", setTitle: (title: string) => {}});