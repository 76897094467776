import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Models = () => {

  const navigation = useNavigate();
  useEffect(() => {
    navigation('/equipment')
  }, [navigation])

  return (
    <div>
    </div>
  )
}

export default Models;
