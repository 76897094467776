import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagCloseButton,
  Text,
  TagLabel,
  ThemingProps,
  MenuDivider,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

export type MultiSelectOption = {
  key: string;
  label: string;
  colorScheme?: ThemingProps["colorScheme"];
};

interface Props {
  label?: string;
  options: MultiSelectOption[];
  useTags?: boolean;
  mode?: "reduce" | "select";
  onChange(selected: MultiSelectOption[]): void;
  storageKey?: string; 
  value?: number[]; 
}

export const MultiSelect = ({
  label,
  options,
  useTags = true,
  mode = "select",
  onChange,
  storageKey,
  value
}: Props) => {
  const [selected, setSelected] = useState<number[]>(value || []);
  const [open, setOpen] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState(false);
  
  useEffect(() => {
    if (storageKey) {
      const storedSelected = localStorage.getItem(storageKey);
      if (storedSelected) {
        setSelected(JSON.parse(storedSelected));
      } else if (mode === "reduce") {
        setSelected(options.map((_, index) => index));
      } else {
        setSelected(value || []);
      }
    } else {
      if(mode === "reduce" ){
        setSelected(options.map((_, index) => index));
      }
      else if(value !== undefined && (value.length === 0 || value.length == options.length)){
        setSelected([]);
      }
    }
  }, []);
  
  useEffect(() => {
    if (storageKey) {
      localStorage.setItem(storageKey, JSON.stringify(selected));
    }
  }, [storageKey, selected]);

  const selectAll = () => {
    if (mode === "reduce") {
      setSelected(options.map((_, index) => index));
    } else {
      setSelected([]);
    }
  };

  const toggleOption = (
    event: React.MouseEvent<HTMLButtonElement>,
    optionIndex: number
  ) => {
    event.stopPropagation();
    if (mode === "reduce") {
      if (selected.length === 1 && selected.includes(optionIndex)) {
        return;
      }
    } else {
      if (
        !selected.includes(optionIndex) &&
        [...selected, optionIndex].length === options.length
      ) {
        setSelected([]);
        return;
      }
    }
    if (selected.includes(optionIndex)) {
      setSelected(selected.filter((item) => item !== optionIndex));

    } else {
      setSelected([...selected, optionIndex]);
    }
  };

  useEffect(() => {
    if (!isFirstRender) {
      setIsFirstRender(true);
      return;
    }

    if (selected.length === 0) {
      onChange(options);
      return;
    }
    const selectedOptions = options.filter((_option, index) => {
      return selected.includes(index);
    });
    onChange(selectedOptions);
  }, [selected]);

  return (
    <VStack align="left">
      <Menu
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        closeOnSelect={false}
      >
        {label ? <Text fontWeight="bold">{label}</Text> : null}
        <MenuButton
          as={Button}
          aria-label="Options"
          variant="outline"
          rightIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        >
          {useTags ? (
            selected.length === 0 ? (
              "All"
            ) : (
              <Stack direction="row" spacing={2}>
                {selected.map((index) => {
                  return (
                    <Tag
                      pointerEvents={"auto"}
                      key={index}
                      colorScheme={options[index].colorScheme ?? "gray"}
                    >
                      <TagLabel>{options[index].label}</TagLabel>
                      <TagCloseButton
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                          toggleOption(event, index)
                        }
                      />
                    </Tag>
                  );
                })}
              </Stack>
            )
          ) : selected.length === options.length ? (
            "All"
          ) : (
            "Showing " + selected.length + " of " + options.length
          )}
        </MenuButton>
        <MenuList>
          {mode === "reduce" ? (
            <MenuItem
              icon={
                selected.length === options.length ? <CheckIcon /> : undefined
              }
              onClick={() => selectAll()}
            >
              All
            </MenuItem>
          ) : (
            <MenuItem
              icon={selected.length === 0 ? <CheckIcon /> : undefined}
              onClick={() => selectAll()}
            >
              All
            </MenuItem>
          )}
          <MenuDivider />
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                icon={selected.includes(index) ? <CheckIcon /> : undefined}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  toggleOption(event, index)
                }
              >
                {option.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </VStack>
  );
};