import { Center, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, useColorModeValue } from "@chakra-ui/react";
import { ItemRentModal } from "../ItemRentModal";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFetch } from "../../contexts/FetchContext";
import { Item } from "../../models/item";

export const RentModal = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState<Item>()
  const { backend } = useFetch();

  const onClose = () => {
    navigate(-1);
  };

  /* Get computer */
  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id]);

  const refetch = () => {
    backend.items.getById(parseInt(id!))
    .then((data) => {
      if (data) {
        setItem(data);
      }else{
        onClose()
      }
    });
  }

  const bodyBg = useColorModeValue("white", "gray.900")

  return(
    <>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size='3xl'>
        <ModalOverlay 
          backdropFilter='auto'
          backdropBlur='5px'
          onClick={() => navigate(-1)}
        />
        {item?
          <ModalContent backgroundColor={bodyBg}>
            <ModalBody p={6}>
                {!item.rentStatus &&
                  <ItemRentModal id={id} onChange={() => refetch()}/>
                }
            </ModalBody>
          </ModalContent>
        :
          <ModalContent bg="transparent">
            <Center>
              <Spinner/>
            </Center>
          </ModalContent>
        }
      </Modal>
    </>
  )
}