import React, { useContext, useRef, useMemo, useCallback } from 'react';
import { IconButton, Tag, ButtonGroup, Tooltip, useColorModeValue, Input, Select, Text, VStack, WrapItem, Button } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PersonIcon from '@mui/icons-material/Person';
import { DeleteIcon, EditIcon, RepeatClockIcon } from '@chakra-ui/icons';
import { Computer } from '../../models/computer';
import { useFetch } from '../../contexts/FetchContext';
import { UserTag } from '../../components/UserTag';
import { TableData } from '../../components/TableData';
import { MultiSelect } from '../../components/MultiSelect';

export const ComputerTable: React.FC = () => {
  const { backend } = useFetch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchItems = useCallback((params: any) => backend.computers.get(params), [backend]);


  const statusOptions = [
    { key: '0', label: 'Owned', colorScheme: 'red' },
    { key: '1', label: 'Rented', colorScheme: 'orange' },
    { key: '2', label: 'Available', colorScheme: 'green' },
  ];



  const buttonGroup = useCallback((computer: Computer) => {
    const isDeleted = computer.deleted && new Date(computer.deleted) > new Date(0);

    const handleNavigation = (path: string) => (event: React.MouseEvent) => {
      event.stopPropagation();
      navigate(path, { state: { previousLocation: location.pathname } });
    };

    const rentButton = computer.rentStatus?.status !== undefined ? (
      <Tooltip label="Return">
        <IconButton
          aria-label="return"
          icon={<KeyboardReturnIcon />}
          onClick={handleNavigation(`/computers/${computer.id}/return`)}
        />
      </Tooltip>
    ) : (
      <Tooltip label="Rent">
        <IconButton
          aria-label="rent"
          icon={<PersonIcon />}
          onClick={handleNavigation(`/computers/${computer.id}/rent`)}
        />
      </Tooltip>
    );

    const deleteOrRestoreButton = isDeleted ? (
      <Tooltip label="Restore">
        <IconButton
          aria-label="restore"
          icon={<RepeatClockIcon />}
          onClick={handleNavigation(`/computers/${computer.id}/restore`)}
        />
      </Tooltip>
    ) : (
      <Tooltip label="Delete">
        <IconButton
          aria-label="delete"
          icon={<DeleteIcon />}
          onClick={handleNavigation(`/computers/${computer.id}/delete`)}
        />
      </Tooltip>
    );

    return (
      <ButtonGroup variant="ghost">
        {rentButton}
        <Tooltip label="Edit">
          <IconButton
            aria-label="edit"
            icon={<EditIcon />}
            onClick={handleNavigation(`/computers/${computer.id}/edit`)}
          />
        </Tooltip>
        {deleteOrRestoreButton}
      </ButtonGroup>
    );
  }, [navigate, location.pathname]);

  const statusTags = (status: number | undefined) => {
    const tagMap = {
      0: <Tag colorScheme='red'>Owned</Tag>,
      1: <Tag colorScheme='orange'>Rented</Tag>,
      2: <Tag colorScheme='green'>Available</Tag>,
      3: <Tag colorScheme='purple'>Deleted</Tag>,
    };
    return tagMap[status as keyof typeof tagMap] || <Tag colorScheme='green'>Available</Tag>;
  };

  const onRowClick = useCallback((element: Computer) => {
    const newPath = `/computers/${element.id}?${searchParams.toString()}`;
    navigate(newPath, { state: { previousLocation: location.pathname } });
  }, [navigate, location.pathname, searchParams]);

  const columns: ColumnDef<Computer>[] = useMemo(() => [
    {
      id: 'computerId',
      accessorFn: row => row.computerId,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'ComputerId',
      cell: (info) => `PC${String(info.getValue()).padStart(3, '0')}`,
    },
    {
      id: 'model',
      accessorFn: row => row.model.name,
      header: 'Model',
      enableHiding: true,
      sortingFn: 'text',
      cell: info => info.getValue(),
    },
    {
      id: 'memory',
      accessorFn: row => row.memory,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Memory',
      cell: (info) => `${info.getValue()} GB`,
    },
    {
      id: 'storage',
      accessorFn: row => row.disk,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Storage',
      cell: (info) => `${info.getValue()} GB`,
    },
    {
      id: 'display',
      accessorFn: row => row.display,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Display',
      cell: (info) => `${info.getValue()}"`,
    },
    {
      id: 'year',
      accessorFn: row => row.year,
      sortingFn: 'auto',
      header: 'Year',
      enableHiding: true,
      cell: (info) => info.getValue(),
    },
    {
      id: 'hasEDM',
      accessorFn: row => row.hasEDM,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'EDM',
      cell: (info) => info.getValue() ? 'Yes' : 'No',
    },
    {
      id: 'serialNumber',
      accessorFn: row => row.serialNumber,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Serial Number',
      cell: (info) => info.getValue(),
    },
    {
      id: 'status',
      accessorFn: row => {
        const isDeleted = row.deleted && new Date(row.deleted) > new Date(0);
        return isDeleted ? 3 : (row.rentStatus?.status ?? 2);
      },
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Status',
      cell: info => statusTags(info.getValue() as number),
    },
    {
      id: 'user',
      accessorFn: row => row.rentHistory[0]?.id ?? null,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'User',
      cell: (info) => info.row.original.rentStatus ? <UserTag email={info.row.original.rentStatus.rentedBy} /> : null,
    },
    {
      accessorFn: row => row.company,
      id: 'company',
      header: 'Company',
      enableHiding: true,
      enableSorting: false,
      cell: info => info.getValue()
    },
  ], []);
  const additionalFilters = useMemo(() => (
    <>
      <WrapItem as={VStack} align="left">
        <Text fontWeight="bold">Search</Text>
        <Input
          placeholder="Search for computers"
          w="300px"
          value={searchParams.get('query') || ''}
          onChange={(e) => {
            const newParams = new URLSearchParams(searchParams);
            if (e.target.value) {
              newParams.set('query', e.target.value);
            } else {
              newParams.delete('query');
            }
            setSearchParams(newParams);
          }}
        />
      </WrapItem>
      <WrapItem as={VStack} align="left">
        <Text fontWeight="bold">Show Deleted</Text>
        <Select
          value={searchParams.get('IncludeDeleted') || 'false'}
          onChange={(e) => {
            const newParams = new URLSearchParams(searchParams);
            if (e.target.value === 'true') {
              newParams.set('IncludeDeleted', 'true');
            } else {
              newParams.delete('IncludeDeleted');
            }
            setSearchParams(newParams);
          }}
        >
          <option value='false'>Do not show deleted</option>
          <option value='true'>Show deleted</option>
        </Select>
      </WrapItem>
      <WrapItem>
        <MultiSelect
          label='Status'
          options={statusOptions}
          onChange={(selected) => {
            const newParams = new URLSearchParams(searchParams);
            const statuses = selected.map(item => item.key);
            if (statuses.length && statuses.length < statusOptions.length) {
              newParams.set('RentStatus', statuses.join(','));
            } else {
              newParams.delete('RentStatus');
            }
            setSearchParams(newParams);
          }}
          value={searchParams.get("RentStatus")?.split(",").map(Number) || []}
        />
      </WrapItem>

      <WrapItem>
        <Button colorScheme='blue' onClick={() => navigate('new')}>Create</Button>
      </WrapItem>
    </>
  ), [searchParams, setSearchParams, navigate]);

  return (
    <>
      <TableData<Computer>
        title="Computers"
        fetchItems={fetchItems}
        columns={columns}
        initialSortColumn=""
        sortDesc={true}
        statusOptions={statusOptions}
        onRowClick={onRowClick}
        buttonGroup={buttonGroup}
        searchParams={searchParams}
        additionalFilters={additionalFilters}
      />
      <Outlet />
    </>
  );
};
