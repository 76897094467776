import { Button, FormLabel, HStack, Heading, IconButton, Input, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Manufacturer, Model } from "../../models/manufacturer";
import { useForm } from '@tanstack/react-form'
import { FormControl } from '@chakra-ui/react'
import { useFetch } from "../../contexts/FetchContext";
import { FormModal } from "../../components/Helpers/FormModal";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

export const CreateManufacturer = () => {

  const navigate = useNavigate()
  const { backend } = useFetch();

  const onClose = () => {
    navigate(-1);
};

  const form = useForm({
    defaultValues: {
      name: '',
      models: [{id: 0, name: ''}] as Model[],
    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit")) {
        const updatedManufacturer: Manufacturer = {
          id: 0,
          name: values.name!,
          models: values.models!
        }
        backend.manufacturers.create(updatedManufacturer)
        .then((response) => {
          onClose();
        }).catch((error) => {
          console.log(error)
        });
      }
    }
  })

  const FormFields = [
    (
      <form.Field 
        name="name" 
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Name is required'
            : undefined
        }}
        children={field => {
          return(
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Name</FormLabel>
              <Input variant='filled' required name={field.name} value={field.state.value} onBlur={field.handleBlur} onChange={(e) => field.handleChange(e.target.value)} placeholder="Name"/>
            </FormControl>
          )
        }}
      />
    ),(
      <form.Field 
        name="models" 
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Name is required'
            : undefined
        }}
        children={field => {
          return (
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <VStack alignItems='stretch'>
                <FormLabel>Models</FormLabel>
                {field.state.value.map((model: Model, index: number) => {
                  return(
                    <HStack key={index} justifyContent='space-between' alignItems='center'>
                      <Input variant='filled' required name={field.name} value={model.name} onBlur={field.handleBlur} onChange={(e) => {
                        const newModels = [...field.state.value];
                        newModels[index] = {id: newModels[index].id, name: e.target.value} as Model;
                        field.setValue(newModels);
                      }} placeholder="Name"/>
                      <IconButton aria-label="remove" icon={<MinusIcon/>} onClick={() => {
                        const newModels = [...field.state.value];
                        newModels.splice(index, 1);
                        field.setValue(newModels);
                      }}/>
                    </HStack>
                  )
                })}
                <HStack justifyContent='end'>
                  <IconButton aria-label="remove" icon={<AddIcon/>} onClick={() => {
                    const newModels = [...field.state.value];
                    newModels.push({id: 0, name: ''} as Model);
                    field.setValue(newModels);
                  }}/>
                </HStack>
              </VStack>
            </FormControl>
          )
        }}
      />
    )
  ]

  const SubmitButton = () => {
    return(
      <Button colorScheme='blue' isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
        Create
      </Button>
    )
  }

  return (
    <FormModal
      isLoading={true}
      headerElements={[<Heading size='xl' mt={5}>Create Manufacturer</Heading>]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton/>}
      onClose={onClose}
    />
  )
}