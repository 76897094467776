import { AxiosResponse } from "axios";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { Keycard } from "../../models/keycard";

export const keycardsAPI = (API: APIType, URL: string) => {
  const keycardURL = URL + 'keycard';

  const options = {
    getAll: async (params?: any) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: keycardURL,
        param: params
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {
        return response.data as Keycard[];
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      });
    },
    getById: async (id: number) => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${keycardURL}/${id}`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as Keycard;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    create: async (keycard: Keycard) => {
      const APIParams = {
        method: Method.post,
        needToken: true,
        URL: keycardURL,
        body: JSON.stringify(keycard)
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as Keycard;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    update: async (keycard: Keycard) => {
      const APIParams = {
        method: Method.put,
        needToken: true,
        URL: keycardURL,
        body: JSON.stringify(keycard)
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as Keycard;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    }
  }
  return options;
}
