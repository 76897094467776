import React, { ChangeEvent, useRef, useState } from "react";
import { createWorker } from 'tesseract.js';
import { Box, Button, HStack, VStack, useToast } from "@chakra-ui/react";
import { Progress, Text } from '@chakra-ui/react'
import ImageSearchIcon from '@mui/icons-material/ImageSearch';

export interface DeviceSpecs {
    ram: string;
    storage: string;
    display: string;
}

export interface ScanProps {
    manufacturer?: string;
    serial?: string;
    model?: string;
    hasEDM?: boolean;
    computerID?: number;
    data?: string,
    specs?: DeviceSpecs;
};

interface ChildComponentProps {
    callback: (data: ScanProps) => void;
}

/* Apple Specs
 * i13.3spg/8c cpu/bc gpu/16gb/1tb/kb-h/noe
 * 15.4/3.1GHz/Radeon Pro 560/16GB/512GB/NOB/INT
 */

const ImageScanner: React.FC<ChildComponentProps> = ({ callback }) => {

    const toast = useToast();

    const inputRef = useRef<HTMLInputElement | null>(null)
    const handleClick = () => inputRef.current?.click()

    var [progress, setProgress] = useState(0);
    var [text, setText] = useState('');
    const [isLoading, setLoading] = useState(false);

    /* parse a Apple Device */
    const parseAppleDeviceSpecs = (text: string): ScanProps => {
        const list = text.split("\n");

        let specs: ScanProps = {
            manufacturer: "Apple",
            specs: {
                ram: "",
                storage: "",
                display: "",
            }
        };

        /* Parse lines for serial number, model and specs */
        for (let i = 0; i < list.length; i++) {
            if (list[i].includes("serial no.")) {
                specs.serial = list[i].split("serial no.")[1].trim().toUpperCase();
            }
            if (list[i].includes("macbook")) {
                specs.model = list[i].split("macbook")[1].trim().split(" ")[0];
            }
            if (list[i].includes("mba") || list[i].includes("mbp")) {
                specs.data = list[i].includes("mba") ?
                    list[i].split("mba")[1].trim().toLowerCase() :
                    list[i].split("mbp")[1].trim().toLowerCase();
            }
        }

        /* Parse specs */
        const ramMatch = specs.data!.match(/(\d+)gb/i);
        if (ramMatch) {
            specs.specs!.ram = ramMatch[1] + "GB";
        }

        const storageMatch = specs.data!.match(/(\d+)tb/i);
        if (storageMatch) {
            /* Convert TB to GB */
            specs.specs!.storage = (parseInt(storageMatch[1])*1024).toString() + "GB";
        } else {
            const storageGBMatch = specs.data!.match(/(\d+)gb\/(\d+)gb/i);
            if (storageGBMatch) {
                specs.specs!.storage = storageGBMatch[2] + "GB";
            }
        }

        const displayMatch = specs.data!.match(/(\d+\.?\d*)/);
        if (displayMatch) {
            specs.specs!.display = displayMatch[1].split(".")[0];
        }
        return specs;
    }

    /* Parse text from image */
    const parseText = (text: string) => {
        const raw_data = text.toLowerCase();

        if (raw_data.includes("macbook")) {
            const specs = parseAppleDeviceSpecs(raw_data);

            if (specs) {
                toast({
                    description: "Found "+specs.manufacturer + " MacBook "+specs.model,
                    status: "success",
                    duration: 4000,
                });
                setLoading(false)
                /* Callback to parent */
                callback(specs!);
                return;
            }
        }
        toast({
            description: "Unable to find data.",
            status: "error",
            duration: 4000,
        });
        setLoading(false)
    };

    /* Read file and parse text */
    const readFile = async (file: File) => {
        const worker = await createWorker({
            logger: m => {
                setProgress(m.progress * 100);
                setText(m.status);
            }
        });
        await worker.loadLanguage('eng');
        await worker.initialize('eng');
        const { data: { text } } = await worker.recognize(file);
        parseText(text);
        await worker.terminate();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        const file = event.target.files && event.target.files[0];
        if (file) {
            readFile(file);
        }
    };


    return (
        <HStack width='100%' align='end'>
            <Box onClick={handleClick}>
                <input
                    type={'file'}
                    multiple={false}
                    hidden
                    ref={(e) => {
                        inputRef.current = e
                    }}
                    onChange={handleFileChange}
                />
                <Button leftIcon={<ImageSearchIcon/>} isLoading={isLoading}>
                    Scan Image
                </Button>
            </Box>
            {progress > 0 &&
                <VStack width='100%' align='stretch'>
                    <Text>{text}</Text>
                    <Progress value={progress} />
                </VStack>
            }
        </HStack>
    )
}

export default ImageScanner;
