import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./msalprovider";
import { AuthBarrier } from "./AuthBarrier";
import { ReactNode, useEffect, useState } from "react";
import { Flex, Spinner } from "@chakra-ui/react";

type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);

  useEffect(() => {
    const initializeMsal = async () => {
      const instance = new PublicClientApplication(msalConfig);
      await instance.initialize();
      setMsalInstance(instance);
    };

    initializeMsal();
  }, []);

  if (!msalInstance) {
    return (
      <Flex w="full" alignItems="center" justifyContent="center" p="20" >
        <Spinner />
      </Flex>
    );
  }

  return (
    <MsalProvider instance={msalInstance}>
      <AuthBarrier>
        {children}
      </AuthBarrier>
    </MsalProvider>
  );
}
