import { useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, Tag, ButtonGroup, Stack, Tooltip, Button } from '@chakra-ui/react';
import { TanstackTable } from '../../components/TanstackTable';
import { ColumnDef, InitialTableState } from '@tanstack/react-table';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { useFetch } from '../../contexts/FetchContext';
import { Manufacturer } from '../../models/manufacturer';

export const ManufacturerTable = () => {

  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [params, setParams] = useState<{[x: string]: string}>({});
  const [tableData, setTableData] = useState<Manufacturer[]>([]);
  const app = useContext(AppContext);
  const { backend } = useFetch()

  const navigation = useNavigate()
  const location = useLocation()

  useEffect(() => {
    console.log(location.pathname)
    if (location.pathname === '/manufacturers') refetch()
  }, [location, params])

  const refetch = () => {
    backend.manufacturers.get(params).then((manufacturer) => {
      setManufacturers(manufacturer)
    }).catch((err) => {
      console.log(err)
    })
  }

  const updateParams = (key: string, value: string) => {
    setParams(prevParams => ({...prevParams, [key]: value}))
  }

  const isLoading = useMemo(() => {
    return manufacturers ? false : true
  }, [manufacturers])

  useEffect(() => {
    setTableData(manufacturers)
  }, [manufacturers])

  app.setTitle("Manufacturers")


  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
  }

  const buttonGroup = (id: number) => {
    return(
      <ButtonGroup variant="ghost">
        <Tooltip label="Edit"><IconButton aria-label="edit" icon={<EditIcon />} onClick={(event) => {event.stopPropagation(); navigation(`${id}/edit`, { state: { previousLocation: location.pathname} })}} /></Tooltip>
        <Tooltip label="Delete"><IconButton aria-label="delete" icon={<DeleteIcon />} onClick={(event) => {event.stopPropagation(); navigation(`${id}/delete`, { state: { previousLocation: location.pathname} })}} /></Tooltip>
      </ButtonGroup>
    )
  }

  const statusTags = (status: number | undefined) => {
    if (status != undefined) {
      switch (status) {
        case 0:
          return <Tag colorScheme='red'>Owned</Tag>
        case 1:
          return <Tag colorScheme='orange'>Rented</Tag>
        default:
          throw new Error("Invalid status")
      }
    }else{
      //case rentStatus.AVAILABLE:
      return <Tag colorScheme='green'>Available</Tag>
    }
  }

  const userTags = (userEmail: string | undefined) => {
    if (userEmail) {
      return <Tag colorScheme='blue' onClick={(e) => {e.stopPropagation(); navigation('/users/' + userEmail)}}>{userEmail/* .split('@')[0] */}</Tag>
    }
  }

  const onRowClick = (id: number) => {
    navigation('/keycards/' + id, { state: { previousLocation: location.pathname } })
  }

  const columns: ColumnDef<Manufacturer>[] = [
    // {
    //   id: 'id',
    //   accessorFn: row => row.id,
    //   header: '#',
    //   enableHiding: false,
    //   cell: (info) => `#${info.getValue()}`,
    // },
    {
      id: 'name',
      accessorFn: row => row.name,
      header: 'Name',
      enableHiding: false,
      cell: (info) => info.getValue(),
      sortDescFirst: true,
    },
    {
      id: 'models',
      accessorFn: row => row.models,
      header: 'Models',
      enableHiding: false,
      cell: (info) => info.row.original.models.map((model) => model.name).join(', '),
      sortDescFirst: true,
    },
    {
      id: 'actions',
      header: 'Actions',
      size: 10,
      enableHiding: false,
      maxSize: 10,
      cell: info => buttonGroup(info.row.original.id),
      meta: {
        isNumeric: true
      }
    },
  ]

  const toogleColumnOptions = columns.filter(column => column.enableHiding).map((column) => ({
    key: column.id as string,
    label: column.header as string
  }))

  return (
    <>
      <Stack direction='row' alignItems='center'>
        <Button colorScheme='blue' onClick={() => navigation('new')}>Create</Button>
      </Stack>
      <TanstackTable
        columns={columns}
        data={tableData}
        initialTableState={initialTableState}
        onSortingChange={(newSorting) => console.log(newSorting)}
        isLoading={isLoading}       
        storageKey='manufacturerTableTableVisibility'/>
      <Outlet />
    </>
  )
}
