import { AxiosResponse } from "axios";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { EquipitUser } from "../../models/user";

export const userAPI = (API: APIType, URL: string) => {
  const userURL = URL + 'User/';

  const options = {
    getAll: async (query: any) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${userURL}PersonFinder?query=${query}`, 
      }
      return API(APIParams).then((response: AxiosResponse) => {
        return response.data as EquipitUser[];
      }).catch((error: any) => {
        console.log(error);
        return [] as EquipitUser[];
      })
    },
  }
  return options;
}