import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Box, Button, Center, Spinner, VStack, useColorModeValue } from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { redirectLoginRequest, silentLoginRequest } from './msalprovider';
import { UserContext, UserContextType, UserRoleEnum } from './Auth';
import { isExpired } from 'react-jwt';
import { APIContextProvider } from '../contexts/FetchContext';
import axios from 'axios';

type AuthBarrierProps = {
  children: ReactNode
}

export const AuthBarrier = ({children}: AuthBarrierProps) => {
  const [currentUser, setCurrentUser] = useState<UserContextType>(null);
  const [isLoading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const isAuthenticated = useIsAuthenticated()

  const bg = useColorModeValue('gray.50', 'gray.800')

  const { instance, inProgress, accounts } = useMsal()

  const login = () => {
    setLoading(true)
    setError(undefined)
    setCurrentUser(null)
    console.debug('Logging in silently')
    instance.loginRedirect(silentLoginRequest).catch(e => {
      console.error(e)
    })
  }

  const fetchToken = async () => {
    console.debug('Fetching token')
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      return instance.acquireTokenSilent({
        ...silentLoginRequest,
        account: accounts[0],
      }).then((authenticationResult) => {
        if (!isExpired(authenticationResult.idToken)) {
          return authenticationResult.idToken
        } else {
          login()
          return ""
        }
      }).catch(e => {
        instance.acquireTokenRedirect(redirectLoginRequest)
        return ""
      });
    }
    login()
    return ""
  }

  const getRole = async (token: string, username: string): Promise<UserRoleEnum> => {
    try {
      const response = await axios.get(`${process.env["REACT_APP_EQUIPIT_BACKEND"]}User/GetRoles/${username}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.data[0]) {
        const role = response.data[0];
        if (Object.values(UserRoleEnum).includes(role)) {
          return role;
        }
      }
      throw new Error('Invalid role');
    } catch (error) {
      console.error('Error getting role:', error);
      setError('Failed to get user role');
      throw error;
    }
  };

  const setUser = async () => {
    if (!accounts[0]) return;

    try {
      const token = await fetchToken();
      if (!token) return;

      const role = await getRole(token, accounts[0].username);
      setCurrentUser({
        email: accounts[0].username,
        name: accounts[0].name ?? '',
        role: role,
      });
    } catch (error) {
      console.error('Error setting user:', error);
      setError('Failed to set user');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!currentUser && isAuthenticated && inProgress === InteractionStatus.None && accounts && accounts.length > 0) {
      setCurrentUser(null)
      setUser()
    }
  }, [accounts, currentUser, isAuthenticated, inProgress])


  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      login()
    }
  }, [isAuthenticated, isLoading, error, inProgress])

  return(
    <Box h="100%" w="100%" bg={bg}>
      {!error ? (
        !isLoading ? (
          <>
            <AuthenticatedTemplate>
              {!isLoading ? (
                currentUser ?(
                  <UserContext.Provider value={currentUser}>
                    <APIContextProvider fetchToken={fetchToken}>
                      {children}
                    </APIContextProvider>
                  </UserContext.Provider>
                ):(
                  <Center h='100%'>
                    <Spinner size='xl'/>
                  </Center>
                )
              ) : (
                <Center h='100%'>
                  <Button onClick={() => login()}>Log in</Button>
                </Center>
              )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Center h='100%'>
                <Button onClick={() => login()}>Log in</Button>
              </Center>
            </UnauthenticatedTemplate>
          </>
        ):(
          <Center h='100%'>
            <Spinner size='xl'/>
          </Center>
        )
      ) : (
        <Center h='100%'>
          <VStack>
            <Button onClick={() => login()}>Log in</Button>
            <p>{error}</p>
          </VStack>
        </Center>
      )}
    </Box>
  )
}

export { UserContext };
