import { Stack } from "@chakra-ui/react";
import { useEffect, useState, useContext, useMemo } from "react";
import { useParams, useLocation, Outlet } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { useFetch } from "../../contexts/FetchContext";
import { InitialTableState } from "@tanstack/react-table";
import { ItemCollection } from "../../models/itemCollection";
import { UserComputer } from "./UserComputer";
import { UserKeyCards } from "./UserKeyCards";
import { UserLicenses } from "./UserLicenses";
import { License } from "../../models/newLicenses";

export const User = () => {
  const [items, setItems] = useState<ItemCollection>();
  const [licenses, setLicenses] = useState<License[]>();

  const { email } = useParams();

  const app = useContext(AppContext);
  const {backend} = useFetch();
  app.setTitle("User")
  const location = useLocation()

  useEffect(() => {
    refetch()
  }, [email])

  const refetch = () => {
    if (email){
      backend.items.getByEmail(email).then((data) => {
        if (data) {
          setItems(data);
        }
      }).catch((err) => {
        console.log(err)
      })
      backend.licenses.getAll({UserEmails: [email]}).then((licenses: License[]) => {
        if (licenses.length > 0) {
          setLicenses(licenses);
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  useEffect(() => {
    if (location.pathname === '/equipment' || location.pathname.startsWith('/user'))
      refetch()
  }, [location])

  const isLoading = useMemo(() => {
    return items ? false : true
  }, [items])

  app.setTitle(email? email : "User")


  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
    columnVisibility: {
      'cardId': false
    }
  }

  return (
    <Stack spacing='4'>
      {email && (
        <>
          {(items?.computers?.length || 0) > 0 && (
            <UserComputer email={email} computers={items!.computers!}/>
          )}
          {(items?.keyCards?.length || 0) > 0 && (
            <UserKeyCards email={email} keycards={items!.keyCards!}/>
          )}
          {(licenses?.length || 0) > 0 && (
            <UserLicenses email={email} licenses={licenses!}/>
          )}
        </>
      )}
      <Outlet />
    </Stack>
  )
}
