import { useRef, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { EquipitUser } from "../models/user";
import { Button } from "@chakra-ui/react";
import { FormControl, Heading, Select, ModalFooter, VStack, useToast, Checkbox, Input, SimpleGrid } from "@chakra-ui/react";
import ResponseHandler from "./Helpers/ResponseHandler";
import { Responses } from "./Helpers/ResponseHandler";
import {AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList,} from "@choc-ui/chakra-autocomplete";
import { dotNetError } from "../models/dotnetDataModels";
import { UserContext } from "../auth/Auth";
import { useFetch } from "../contexts/FetchContext";
import { debounce } from "lodash";
import AutoExpandingTextarea from "./AutoExpandingTextarea";

interface ItemRentModalProps {
    id?: string;
    onChange: () => void;
}

export const ItemRentModal = (props: ItemRentModalProps) => {

    const id = props.id;
    const [userList, setUserList] = useState<EquipitUser[]>([]);
    const [selectedOption, setSelectedOption] = useState<EquipitUser | null>(null);
    const [status, setStatus] = useState<number>(1);
    const [note, setNote] = useState<string>("");
    const [external, setExternal] = useState<boolean>(false);

    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const {backend} = useFetch();

    const toast = useToast()

    const buttonRef = useRef<HTMLButtonElement>(null);


  const fetchData = async (query: string) => {
    return await backend.users.getAll(query)
    .then((res) => {
      if (res) {
        return res.map((user): EquipitUser => {
          return {
            displayName: user.displayName,
            email: user.email,
            company: user.company,
            department: user.department,
          };
        })
      }
      return [];
    })
  };

  const debouncedFilter = debounce(async (query: string) => {
    if (!query) return setUserList([]);
    setUserList(await fetchData(query));
  }, 200);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e.target.value);
  };

  const handleChange = (selectedOption: any) => {
    buttonRef.current?.focus();
    setSelectedOption(selectedOption)
  }

  const handleRent = () => {
    if (selectedOption && userContext){
      backend.items.rent(parseInt(id!), selectedOption.displayName, selectedOption.email, selectedOption.company, status, note).then(() => {
        // Loading screen and success
        ResponseHandler(`Sucessfully rented item 🤘`, Responses.Success, toast);

        props.onChange();
        navigate(-1);
    }).catch((error: dotNetError) => {
        ResponseHandler(error.response.data.detail, Responses.Error, toast);
        props.onChange();
      });
    }
  }

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  }

  return (
    <VStack align='stretch' spacing={4}>
      <Heading size='md'>Equip to</Heading>
      <Checkbox
        onChange={(e) => setExternal(e.target.checked)}
        isChecked={external}
      >
        External
      </Checkbox>
      <FormControl>
        <SimpleGrid columns={2} spacing={4}>
          {
            !external &&
            <AutoComplete onSelectOption={(item) => handleChange(item.item.originalValue)}>
              <AutoCompleteInput
                autoFocus
                placeholder="Search for user"
                onChange={handleInputChange}
              />
              <AutoCompleteList>
                {userList.map((user, index) => {
                  return (
                    <AutoCompleteItem
                      key={index}
                      value={user}
                    >
                      {user.displayName}
                    </AutoCompleteItem>
                  )
                })}
              </AutoCompleteList>
            </AutoComplete>
          }
          {
            external &&
            <Input
              autoFocus
              placeholder="External User"
              onChange={(e) => {
                setSelectedOption({
                  displayName: e.target.value,
                  email: e.target.value,
                  company: "External",
                  department: "External"
                })
              }}
            />
          }
          <Select
            onChange={(e) => setStatus(parseInt(e.target.value))}
            value={status}
            isRequired
          >
            <option value={1}>Rented</option>
            <option value={0}>Owned</option>
          </Select>
        </SimpleGrid>
      </FormControl>
      <AutoExpandingTextarea
        placeholder="Notes (optional)"
        onChange={handleNoteChange}
      />
      <ModalFooter>
        <Button
          ref={buttonRef}
          isDisabled={selectedOption === null}
          onClick={handleRent}
          colorScheme="blue"
          size='md'
        >
          Equip
        </Button>

      </ModalFooter>
    </VStack>
  )
}
