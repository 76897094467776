import { backendAPI } from "./backend"
import { APIType } from "../contexts/FetchContext";
import { licenseAPI } from "./licenses";

export type fromAPIType = {
  backend: ReturnType<typeof backendAPI>,
  licenseIt: ReturnType<typeof licenseAPI>,
}

export const fromAPI = (API: APIType) => {
  const options = {
    backend: backendAPI(API),
    licenseIt: licenseAPI(API),
  }
  return options;
}