export enum Responses {
    Success = 'success',
    Error = 'error',
}

const ResponseHandler = (message: string, type: Responses, toast: any) => {

    switch (type) { 
        case Responses.Success:
            toast({
                duration: 4000,
                status: 'success',
                description: message,
            })
            break;
        case Responses.Error:
            toast({
                duration: 4000,
                description: message,
                status: 'error',
            })
            break;
        default:
            break;
    }

};


export default ResponseHandler;