import { Equipment } from "./pages/Equipment";
import { ComputerTable } from "./pages/Computers/ComputerTable";
import { ComputerModal } from "./pages/Computers/ComputerModal";
import Home from "./pages/Home";
import { User } from "./pages/User/User";
import { RouteObject } from "react-router-dom";
import { Layout } from "./components/Layout";
import { CreateComputer } from "./pages/Computers/CreateComputer";
import { ReturnModal } from "./components/Helpers/ReturnModal";
import { RentModal } from "./components/Helpers/RentModal";
import { EditComputer } from "./pages/Computers/EditComputer";
import { KeycardTable } from "./pages/Keycards/KeycardTable";
import { DeleteModal } from "./components/Helpers/DeleteModal";
import { CreateKeycard } from "./pages/Keycards/CreateKeycard";
import { ManufacturerTable } from "./pages/Manufacturers/ManufacturerTable";
import { CreateManufacturer } from "./pages/Manufacturers/CreateManufacturer";
import { EditManufacturer } from "./pages/Manufacturers/EditManufacturer";
import { EditKeycard } from "./pages/Keycards/EditKeycard";
import { KeycardModal } from "./pages/Keycards/KeyCardModal";
import { LicenseTable } from "./pages/Licenses/LicenseTable";
import { ApproveLicense } from "./pages/Licenses/ApproveLicense";
import { CreateLicense } from "./pages/Licenses/CreateLicense";
import { ManageLicenses } from "./pages/Licenses/ManageLicenses";
import { ValidateLicense } from "./pages/Licenses/ValidateLicense";
import { InvalidateLicense } from "./pages/Licenses/InvalidateLicense";
import ModalType from './models/endpointEnums';
import { RestoreModal } from "./components/Helpers/RestoreModal";
import { EditUserRole } from "./pages/Users/EditUserRole";
import { CreateRole } from "./pages/Roles/CreateRole";
import { EditRolePermissions } from "./pages/Roles/EditRolePermission";
import { UserTable } from "./pages/Users/UserTable";
import { RolesTable } from "./pages/Roles/RolesTable";
import { RoleModal } from "./pages/Roles/RoleModal";

export const AppRoutes = (): RouteObject[] => {
  return [
    {
      element: <Layout/>,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: 'equipment',
          element: <Equipment />,
          children: [
            {
              path: 'computer/:id/return',
              element: <ReturnModal/>,
            },
            {
              path: 'computer/:id',
              element: <ComputerModal/>,
            },
            {
              path: 'keycard/:id',
              element: <KeycardModal/>,
            },
            {
              path: 'keycard/:id/return',
              element: <ReturnModal/>,
            },
            {
              path: 'license/:userId/add',
              element: <ApproveLicense/>,
            },
          ]
        },
        {
          path: 'user/:email',
          element: <User/>,
          children: [
            {
              path: 'computer/:id/return',
              element: <ReturnModal/>,
            },
            {
              path: 'computer/:id',
              element: <ComputerModal/>,
            },
            {
              path: 'keycard/:id',
              element: <KeycardModal/>,
            },
            {
              path: 'keycard/:id/return',
              element: <ReturnModal/>,
            },
            {
              path: 'license/:userId/add',
              element: <ApproveLicense/>,
            }
          ]
        },
        {
          path: 'computers',
          element: <ComputerTable />,
          children: [
            {
              path: '?q=:searchQuery&d=:showDeleted&s=:showStatus',
              element: <ComputerTable />,
            },
            {
              path: ':id',
              element: <ComputerModal/>,
            },
            {
              path: ':id/rent',
              element: <RentModal/>,
            },
            {
              path: ':id/return',
              element: <ReturnModal/>,
            },
            {
              path: 'new',
              element: <CreateComputer/>,
            },
            {
              path: ':id/edit',
              element: <EditComputer/>,
            },
            {
              path: ':id/delete',
              element: <DeleteModal modalType={ModalType.ITEM}/>,
            },
            {
              path: ':id/restore',
              element: <RestoreModal modalType={ModalType.ITEM}/>,
            },
          ]
        },
        {
          path: 'keycards',
          element: <KeycardTable />,
          children: [
            {
              path: ':id',
              element: <KeycardModal/>,
            },
            {
              path: ':id/rent',
              element: <RentModal/>,
            },
            {
              path: ':id/return',
              element: <ReturnModal/>,
            },
            {
              path: 'new',
              element: <CreateKeycard/>,
            },
            {
              path: ':id/edit',
              element: <EditKeycard/>,
            },
            {
              path: ':id/delete',
              element: <DeleteModal modalType={ModalType.ITEM}/>,
            },
            {
              path: ':id/restore',
              element: <RestoreModal modalType={ModalType.ITEM}/>,
            },
          ]
        },
        {
          path: 'manufacturers',
          element: <ManufacturerTable />,
          children: [
            {
              path: ':id/rent',
              element: <RentModal/>,
            },
            {
              path: ':id/return',
              element: <ReturnModal/>,
            },
            {
              path: 'new',
              element: <CreateManufacturer/>,
            },
            {
              path: ':id/edit',
              element: <EditManufacturer/>,
            },
            {
              path: ':id/delete',
              element: <DeleteModal modalType={ModalType.MANUFACTURER}/>,
            },
          ]
        },
        {
          path: 'manage-licenses',
          element: <ManageLicenses/>,
          children: [
            {
              path: 'create-license',
              element: <CreateLicense/>,
            },
            {
              path: ':tableName/:tableId/invalidate',
              element: <InvalidateLicense/>,
            },
            {
              path: ':tableName/:tableId/validate',
              element: <ValidateLicense/>,
            },
          ]
        },
        {
          path: 'licenses',
          element: <LicenseTable/>,
          children: [
            {
              path: ':userId/add',
              element: <ApproveLicense/>,
            },
            {
              path: ':id/delete',
              element: <DeleteModal modalType={ModalType.LICENSE}/>,
            },
          ]
        },
        {
          path: 'users',
          element: <UserTable/>,
          children: [
            {
              path: ':username/edit',
              element: <EditUserRole/>,
            },
          ]
        },
        {
          path: 'roles',
          element: <RolesTable/>,
          children: [
            {
              path: 'new',
              element: <CreateRole/>,
            },
            { 
              
              path  : ':id/edit',
              element: <EditRolePermissions/>,
            },
            {
              path: ':id',
              element: <RoleModal/>,
            },
          ]
        },
        {
          path: '*',
          element: <Home />,
        },
      ]
    },
  ];
}
