import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { AxiosResponse } from "axios";
import { EquipitUser } from "../../models/user";
import { IdentityRole, User } from "../../models/roles";

export const roleAPI = (API: APIType, URL: string) => {
    const userURL = URL + 'User';
  
    const options = {
      getAllUsers: async (params?: any) => {
          const APIParams: APIParams = {
              method: Method.get,
              needToken: true,
              URL: userURL,
              param: params,
          }
          return API(APIParams).then((response: AxiosResponse) => {
            console.log(response.data,  "responsedataasdlkasdklmasddas")
              return response.data as User[];
          }).catch((error: any) => {
              console.error(error);
              return [] as User[];
          })
      },
       queryUsers: async (query: any) => {
        const APIParams: APIParams = {
          method: Method.get,
          needToken: true,
          URL: `${userURL}/PersonFinder?query=${query}`, 
        }
        return API(APIParams).then((response: AxiosResponse) => {
          console.log(response)
          return response.data as EquipitUser[];
        }).catch((error: any) => {
          console.log(error);
          return [] as EquipitUser[];
        })
      },
      getUserRoles: async (username: string) => {
        const APIParams: APIParams = {
          method: Method.get,
          needToken: true,
          URL: `${userURL}/GetRoles/${username}`
        }
        return API(APIParams)
        .then((response: AxiosResponse) => {
          return response.data as string[];
        }).catch((error: any) => {
          console.error(error);
          throw new Error(error);
        });
      },
      getAllRoles: async (params? : any) => {
        const APIParams: APIParams = {
          method: Method.get,
          needToken: true,
          URL: `${userURL}/Roles`,
          param: params,
        }
        return API(APIParams).then((response: AxiosResponse) => {
          return response.data as IdentityRole[];
        }).catch((error: any) => {
          console.error(error);
          return [] as IdentityRole[];
        });
      },
  
      createRole: async (name: string) => {
        const APIParams: APIParams = {
          method: Method.post,
          needToken: true,
          URL: `${userURL}/Roles`,
          param: { name },
        }
        return API(APIParams).then((response: AxiosResponse) => {
          return response.data as string;
        }).catch((error: any) => {
          console.error(error);
          throw error;
        });
      },
  
      deleteRole: async (name: string) => {
        const APIParams: APIParams = {
          method: Method.delete,
          needToken: true,
          URL: `${userURL}/Roles`,
          param: { name },
        }
        return API(APIParams).then((response: AxiosResponse) => {
          return response.data as string;
        }).catch((error: any) => {
          console.error(error);
          throw error;
        });
      },
  
      addUserToRole: async (username: string, rolename: string) => {
        const APIParams: APIParams = {
          method: Method.post,
          needToken: true,
          URL: `${userURL}/UserRoles`,
          param: { username, rolename },
        }
        return API(APIParams).then((response: AxiosResponse) => {
          return response.data as string;
        }).catch((error: any) => {
          console.error(error);
          throw error;
        });
      },
  
      removeUserFromRole: async (username: string, rolename: string) => {
        const APIParams: APIParams = {
          method: Method.delete,
          needToken: true,
          URL: `${userURL}/UserRoles?username=${username}&rolename=${rolename}`,
        }
        return API(APIParams).then((response: AxiosResponse) => {
          return response.data as string;
        }).catch((error: any) => {
          console.error(error);
          throw error;
        });
      },
    };
  
    return options;
  };