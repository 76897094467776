import { useContext, useEffect, useMemo, useState } from 'react';
import { Tag, ButtonGroup, Stack, Button, VStack, useColorModeValue, WrapItem, Wrap } from '@chakra-ui/react';
import { ColumnDef, InitialTableState } from '@tanstack/react-table';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useFetch } from '../../contexts/FetchContext';
import { AppContext } from '../../contexts/AppContext';
import { TanstackTable } from '../../components/TanstackTable';
import { AddIcon } from '@chakra-ui/icons';
import { LicenseGroup } from '../../models/newLicenses';

export const ManageLicenses = () => {

  const [licenseGroups, setLicenseGroups] = useState<LicenseGroup[]>([]);
  const app = useContext(AppContext);
  const { backend } = useFetch()

  const navigation = useNavigate()
  const location = useLocation()
  const bodyBg = useColorModeValue('white', "gray.900")

  app.setTitle('Manage Licenses')

  useEffect(() => {
    refetch()
  }, [location])

  const refetch = () => {
    backend.licenseGroups.getAll().then((licenseGroups: LicenseGroup []) => {
      setLicenseGroups(licenseGroups)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  const tableData = useMemo(() => {
    return licenseGroups
  }, [licenseGroups])

  const isLoading = useMemo(() => {
    return licenseGroups.length === 0;
  }, [licenseGroups]);

  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
    columnVisibility: {
      'computerId': false
    }
  };


  const filterContainer = () => {
    return (
      <Wrap
        bg={bodyBg}
        borderRadius={6}
        shadow='md'
        p={4}
        w="100%"
        spacing={4}
        marginBottom={4}
        align="flex-end"
      >
        <WrapItem
          as={VStack}
          align="left"
        >
          <Button onClick={() => navigation(`create-license`, { state: { previousLocation: location.pathname} })} colorScheme='blue' leftIcon={<AddIcon/>}>Create License</Button>
        </WrapItem>
      </Wrap>
    )
  }

  const buttonGroup = (active: boolean, displayName: string, id: number) => {
    const actionButton = () => {
      if (active) {
        return <Button aria-label="Invalidate" onClick={(event) => {event.stopPropagation(); navigation(`${displayName}/${id}/invalidate`, { state: { previousLocation: location.pathname} })}}>Invalidate</Button>
      } else {
        return <Button aria-label="Validate" onClick={(event) => {event.stopPropagation(); navigation(`${displayName}/${id}/validate`, { state: { previousLocation: location.pathname} })}}>Validate</Button>
      }
    }
    return(
      <ButtonGroup>
        {actionButton()}
      </ButtonGroup>
    )
  }

  const statusTags = (active: boolean) => {
    if (active) return <Tag colorScheme='green'>Active</Tag>
    else return <Tag colorScheme='red'>Not Active</Tag>
  }

  const columns: ColumnDef<LicenseGroup>[] = [
    {
      id: 'displayName',
      accessorFn: row => row.displayName,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Name',
      cell: (info) => info.getValue()
    },
    {
      id: 'licenseCode',
      accessorFn: row => row.licenseKey,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'License Code',
      cell: (info) => info.getValue()
    },
    {
      id: 'active',
      accessorFn: row => row.active,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Status',
      cell: info => statusTags(info.row.original.active)
    },
    {
      id: 'actions',
      header: 'Actions',
      sortingFn: 'auto',
      enableHiding: false,
      cell: info => buttonGroup(info.row.original.active, info.row.original.displayName, info.row.original.id),
      meta: {
        isNumeric: true
      }
    },
  ]

  useEffect(() => {
    console.log('tabledata length', tableData.length)
  }, [tableData])

  return (
    <>
      <Stack direction='row' alignItems='center'>
        {filterContainer()}
      </Stack>
      <TanstackTable
        columns={columns}
        data={tableData}
        initialTableState={initialTableState}
        onSortingChange={(newSort) => console.log(newSort)}
        isLoading={isLoading}        
        storageKey='manageLicenseTableTableVisibility'/>
      <Outlet />
    </>
  )
}
