import { Center, HStack, Heading, Modal, ModalContent, Spinner, Stack, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, Outlet } from "react-router-dom";
import { Computer } from "../../models/computer";
import { Text } from '@chakra-ui/react'
import { useFetch } from "../../contexts/FetchContext";
import { ItemModal } from "../../components/ItemModal";

export const ComputerModal = () => {
  const [computer, setComputer] = useState<Computer>()
  const navigate = useNavigate();
  const location = useLocation();
  const { backend } = useFetch();
  const { id } = useParams();

  const onClose = () => {
    navigate("/computers" + location.search);
  };

  const getComputerId = (id: number) => {
    if (id) {
      let value = id.toString()
      return 'PC' + value.padStart(3,'0')
    }
  }
  /* Get computer */
  useEffect(() => {
    if (id) {
      refetch()
    } else {
      console.debug('Could not find computer id')
      onClose()
    }
  }, [id]);

  const refetch = () => {
    console.debug(`Fetching computer ${id}`);
    backend.computers.getById(parseInt(id!))
    .then((data) => {
      setComputer(data);
    }).catch((e) => {
      console.error(e)
      onClose()
    })
  }

  return (
    <>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size='3xl'>
        {computer ?
          <ItemModal
            headerLine2={`${computer.manufacturer?.name} ${computer.model?.name}`}
            headerLine1={`${getComputerId(computer.computerId)}`}
            item={computer}
            onClose={onClose}
          >
            <VStack spacing={8} align='flex-start'>
              <Stack direction='column' flex={1} align='stretch' spacing={4}>
                <Heading size='md'>Specifications</Heading>
                <HStack align='flex-start' wrap='wrap' gap={10}>
                  <VStack align='flex-start' spacing={0}>
                    <Heading size='sm'>YEAR</Heading>
                    <Text size='sm'>{computer.year}</Text>
                  </VStack>
                  <VStack align='flex-start' spacing={0}>
                    <Heading size='sm'>SCREEN SIZE</Heading>
                    <Text size='sm'>{computer.display}"</Text>
                  </VStack>
                  <VStack align='flex-start' spacing={0}>
                    <Heading size='sm'>MEMORY</Heading>
                    <Text size='sm'>{computer.memory} GB</Text>
                  </VStack>
                  <VStack align='flex-start' spacing={0}>
                    <Heading size='sm'>STORAGE</Heading>
                    <Text size='sm'>{computer.disk} GB</Text>
                  </VStack>
                  <VStack align='flex-start' spacing={0}>
                    <Heading size='sm'>SERIAL NUMBER</Heading>
                    <Text size='sm'>{computer.serialNumber}</Text>
                  </VStack>
                </HStack>
                <HStack align="flex-start" wrap="wrap" gap={10}>
                  <VStack align='flex-start' spacing={0}>
                    <Heading size='sm'>COMPANY</Heading>
                    <Text size='sm'>{computer.company}</Text>
                  </VStack>
                  <VStack align='flex-start' spacing={0}>
                    <Heading size='sm'>Has EDM</Heading>
                    <Text size='sm'>{computer.hasEDM ? "Yes" : "No"}</Text>
                  </VStack>
                </HStack>
              </Stack>
              {computer.description &&
                <VStack flex={1} align='flex-start' spacing={2} whiteSpace='pre-line'>
                  <Heading size='md'>Description</Heading>
                  <Text size='sm'>{computer.description}</Text>
                </VStack>
              }
            </VStack>
          </ItemModal>
          :
          <ModalContent bg="transparent" shadow="none">
            <Center>
              <Spinner size='lg' />
            </Center>
          </ModalContent>
        }
      </Modal>
      <Outlet />
    </>
  )
}
