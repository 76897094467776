import { AxiosResponse } from "axios";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { Manufacturer } from "../../models/manufacturer";

export const manufacturersAPI = (API: APIType, URL: string) => {
  const manufacturersURL = URL + 'manufacturer';

  const options = {
    get: async (params?: any) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: manufacturersURL,
        param: params
      }
      return API(APIParams).then((response: AxiosResponse) => {
        return response.data as Manufacturer[];
      }).catch((error: any) => {
        console.log(error);
        return [] as Manufacturer[];
      })
    },
    getById: async (id: number) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${manufacturersURL}/${id}`,
      }
      return API(APIParams).then((response: AxiosResponse) => {
        return response.data as Manufacturer;
      }).catch((error: any) => {
        console.log(error);
        return {} as Manufacturer;
      })
    },
    create: async (manufacturer: Manufacturer) => {
      const APIParams: APIParams = {
        method: Method.post,
        needToken: true,
        URL: manufacturersURL,
        body: manufacturer
      }
      return API(APIParams).then((response: AxiosResponse) => {
        return response.data as Manufacturer;
      }).catch((error: any) => {
        console.log(error);
        return {} as Manufacturer;
      })
    },
    update: async (manufacturer: Manufacturer) => {
      const APIParams: APIParams = {
        method: Method.put,
        needToken: true,
        URL: manufacturersURL,
        body: manufacturer
      }
      return API(APIParams).then((response: AxiosResponse) => {
        return response.data as Manufacturer;
      }).catch((error: any) => {
        console.log(error);
        return {} as Manufacturer;
      })
    },
    delete: async (id: number) => {
      const APIParams: APIParams = {
        method: Method.delete,
        needToken: true,
        URL: `${manufacturersURL}/${id}`,
      }
      return API(APIParams).then((response: AxiosResponse) => {
        return response.data as Manufacturer;
      }).catch((error: any) => {
        console.log(error);
        return {} as Manufacturer;
      })
    },
    deleteModel: async (id: number) => {
      const APIParams: APIParams = {
        method: Method.delete,
        needToken: true,
        URL: `${manufacturersURL}/model/${id}`,
      }
      return API(APIParams).then((response: AxiosResponse) => {
        return response.data as Manufacturer;
      }).catch((error: any) => {
        console.log(error);
        return {} as Manufacturer;
      })
    },
  }
  return options;
}
