import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../../contexts/FetchContext';
import { useForm } from '@tanstack/react-form';
import { FormModal } from '../../components/Helpers/FormModal';
import {
  Button,
  Checkbox,
  Heading,
  useToast,
  VStack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { IdentityRole } from '../../models/roles';

export const EditUserRole = () => {
  const [availableRoles, setAvailableRoles] = useState<IdentityRole[]>([]);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { username } = useParams<{ username: string }>();
  const { backend } = useFetch();
  const navigate = useNavigate();
  const toast = useToast();

  const fetchData = useCallback(() => {
    if (username) {
      setLoading(true);

      Promise.all([
        backend.role.getUserRoles(username),
        backend.role.getAllRoles(),
      ])
        .then(([fetchedUserRoles, roles]) => {
          setUserRoles(fetchedUserRoles);
          setAvailableRoles(roles);
          setLoading(true);
        })
        .catch((error) => {
          //setLoading(false);
          toast({
            title: 'Error fetching data',
            description:
              'Unable to load user roles or available roles. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  }, [username, backend.role, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    form.setFieldValue('userRoles', userRoles);
  }, [userRoles]);

  const form = useForm({
    defaultValues: {
      userRoles: [] as string[],
    },
    onSubmit: async (values) => {
      if (!username) {
        return null;
      }

      try {
        const rolesToAdd = values.userRoles.filter(
          (role) => !userRoles.includes(role)
        );
        const rolesToRemove = userRoles.filter(
          (role) => !values.userRoles.includes(role)
        );

        for (const role of rolesToAdd) {
          await backend.role.addUserToRole(username, role);
        }
        for (const role of rolesToRemove) {
          await backend.role.removeUserFromRole(username, role);
        }

        toast({
          title: 'Roles updated',
          description: 'User roles have been successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate(-1);
      } catch (error) {
        console.error('Error updating roles:', error);
        toast({
          title: 'Error updating roles',
          description:
            'There was an error updating the user roles. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const FormFields = [
    <form.Field key="userRoles" name="userRoles">
      {(field) => (
        <FormControl>
          <FormLabel>User Roles</FormLabel>
          <VStack spacing={4} align="stretch">
            {availableRoles.map((role) => (
              <Checkbox
                key={role.id}
                id={`role-${role.id}`}
                isChecked={field.state.value.includes(role.name)}
                onChange={(e) => {
                  const newRoles = e.target.checked
                    ? [...field.state.value, role.name]
                    : field.state.value.filter((r: string) => r !== role.name);
                  field.handleChange(newRoles);
                }}
              >
                {role.name}
              </Checkbox>
            ))}
          </VStack>
        </FormControl>
      )}
    </form.Field>,
  ];

  const SubmitButton = () => (
    <Button
      colorScheme="blue"
      isLoading={form.state.isSubmitting}
      type="submit"
      disabled={!form.state.canSubmit}
    >
      Update Roles
    </Button>
  );

  const Header = () => (
    <Heading size="xl" mt={5}>
      Edit User Roles: {username}
    </Heading>
  );

  const onClose = () => navigate(-1);

  return (
    <FormModal
      isLoading={isLoading}
      headerElements={[<Header key="header" />]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton />}
      onClose={onClose}
    />
  );
};