import { LogLevel } from "@azure/msal-browser";

/**
* Configuration object to be passed to MSAL instance on creation.
* For a full list of MSAL.js configuration parameters, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
*/
export const msalConfig = {
  auth: {
    clientId: `${process.env["REACT_APP_CLIENT_ID"]}`, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${process.env["REACT_APP_TENANT_ID"]}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: `${process.env["REACT_APP_REDIRECT_URI"]}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: `${process.env["REACT_APP_REDIRECT_URI"]}`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // If "true", MSAL will store the auth request state required for validation in the browser cookie.
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Verbose,
    }
  }
};

/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit:
* https://learn.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
export const redirectLoginRequest = {
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  prompt: 'select_account'
};

export const silentLoginRequest = {
    scopes: ['openid', 'profile', 'email', 'offline_access'],
}
