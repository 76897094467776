import React, {useContext } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from "react-router-dom";
import ResponseHandler, {Responses} from './ResponseHandler';
import { dotNetError } from '../../models/dotnetDataModels';
import { UserContext } from '../../auth/Auth';
import { useFetch } from '../../contexts/FetchContext';
import ModalType from '../../models/endpointEnums';

interface ModalProps {
    modalType: ModalType;
}

export const DeleteModal = ({modalType}: ModalProps) => {
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const user = useContext(UserContext);

    const navigate = useNavigate();

    const toast = useToast();

    const { id } = useParams();

    const {backend} = useFetch();

    const modalTypeFunction = (function () {
        switch(modalType) {
            case ModalType.ITEM:
                return () => backend.items.softDelete(parseInt(id!));
            case ModalType.MANUFACTURER:
                return () => backend.manufacturers.delete(parseInt(id!));
            case ModalType.LICENSE:
                return () => backend.licenses.delete(parseInt(id!));
        }
    })();

    const onClose = () => {
        navigate(-1);
    };

    const handleReturn = () => {
        if (user) {
            modalTypeFunction()
            .then(() => {
                ResponseHandler(`Sucessfully deleted item 🤘`, Responses.Success, toast);
                onClose();
            }).catch((error: dotNetError) => {
                ResponseHandler(error.response.data.detail, Responses.Error, toast);
            })
        }
    }

    return (
        <div>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={true}
                isCentered
            >
                <AlertDialogOverlay/>

                <AlertDialogContent>
                    <AlertDialogHeader>Delete Item?</AlertDialogHeader>
                    <AlertDialogCloseButton/>

                    <AlertDialogBody>
                        Are you sure you want to delete this item?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme='grey' variant='ghost' ref={cancelRef} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='red' ml={3} onClick={handleReturn}>Delete</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    )
}
