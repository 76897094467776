import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Center, Heading, Modal, ModalContent, Spinner, Stack, VStack, HStack, Text, ModalOverlay, ModalHeader, ModalBody, ModalFooter, Button, IconButton, Tooltip, useColorModeValue, Box } from "@chakra-ui/react";
import { useFetch } from "../../contexts/FetchContext";
import { IdentityRole } from "../../models/roles";
import { EditIcon } from "@chakra-ui/icons";

export const RoleModal = () => {
    const [role, setRole] = useState<IdentityRole>();
    const navigate = useNavigate();
    const location = useLocation();
    const { backend } = useFetch();
    const { id } = useParams();

    const onClose = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (id) {
            refetch();
        } else {
            console.debug('Could not find role id');
            onClose();
        }
    }, [id]);

    const refetch = () => {
        backend.role.getAllRoles({ id: id })
            .then((data) => {
                const foundRole = data.find(role => role.id === id);
                if (foundRole) {
                    setRole(foundRole);
                } else {
                    console.error('No role found with ID:', id);
                    onClose();
                }
            }).catch((e) => {
                console.error('Error fetching role:', e);
                onClose();
            });
    };

    const formatDateTime = (dateTimeString: string): string => {
        const date = new Date(dateTimeString);
        return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const headerBg = useColorModeValue('gray.100', 'gray.900');
    const bodyBg = useColorModeValue('white', 'gray.800');

    const Header = () => {
        return (
            <ModalHeader backgroundColor={headerBg} borderTopRadius={6}>
                <VStack align='flex-start'>
                    <Heading size='xl' marginTop={0}>{role?.name}</Heading>
                    <HStack justifyContent='space-between' w='100%'>
                        <Text>Edit Role</Text>
                        <Tooltip label="Edit">
                            <IconButton
                                icon={<EditIcon />}
                                mr={3}
                                onClick={() => navigate(`/roles/${role?.id}/edit`, { state: { previousLocation: location.pathname } })}
                                aria-label="Edit"
                            />
                        </Tooltip>
                    </HStack>
                </VStack>
            </ModalHeader>
        );
    };

    return (
        <Modal isCentered={true} isOpen={true} onClose={onClose} size='3xl'>
            <ModalOverlay
                backdropFilter='auto'
                backdropBlur='5px'
                onClick={onClose}
            />
            {role ? (
                <ModalContent backgroundColor={bodyBg}>
                    <Header />
                    <ModalBody p={6}>
                        <VStack spacing={8} align='flex-start'>
                            <Stack direction='column' flex={1} align='flex-start' spacing={4}>
                                <Heading size='md'>Role Details</Heading>
                                <HStack align='flex-start' wrap='wrap' gap={10}>
                                    <VStack align='flex-start' spacing={0}>
                                        <Heading size='sm'>Created At</Heading>
                                        <Text size='sm'>{formatDateTime(role.createdAt)}</Text>
                                    </VStack>
                                    <VStack align='flex-start' spacing={0}>
                                        <Heading size='sm'>Created By</Heading>
                                        <Text size='sm'>{role.createdBy}</Text>
                                    </VStack>
                                    <VStack align='flex-start' spacing={0}>
                                        <Heading size='sm'>Last Modified At</Heading>
                                        <Text size='sm'>{formatDateTime(role.lastModifiedAt)}</Text>
                                    </VStack>
                                    <VStack align='flex-start' spacing={0}>
                                        <Heading size='sm'>Last Modified By</Heading>
                                        <Text size='sm'>{role.lastModifiedBy}</Text>
                                    </VStack>
                                    <VStack align='flex-start' spacing={0}>
                                        <Heading size='sm'>Company</Heading>
                                        <Text size='sm'>{role.company}</Text>
                                    </VStack>
                                </HStack>
                            </Stack>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={onClose} variant='outline'>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            ) : (
                <ModalContent bg="transparent" shadow="none">
                    <Center>
                        <Spinner size='lg' />
                    </Center>
                </ModalContent>
            )}
        </Modal>
    );
};