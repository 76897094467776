import { Button, FormLabel, HStack, Heading, Input, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Computer } from "../../models/computer";
import { Manufacturer, Model } from "../../models/manufacturer";
import { Select } from '@chakra-ui/react';
import { useForm } from '@tanstack/react-form'
import { ScanProps } from "../../components/Helpers/ImageScanner";
import { Textarea, FormControl } from '@chakra-ui/react'
import ImageScanner from "../../components/Helpers/ImageScanner";
import { useFetch } from "../../contexts/FetchContext";
import { FormModal } from "../../components/Helpers/FormModal";


export const CreateComputer = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [imageSpecs, setImageSpecs] = useState<ScanProps>();
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>();
  const [manufacturer, setManufacturer] = useState<Manufacturer>();

  const navigate = useNavigate()
  const location = useLocation();
  const { backend } = useFetch();
  const toast = useToast();

  const refetch = () => {
    setLoading(true)
    backend.manufacturers.get()
      .then((data) => {
        setManufacturers(data);
        setLoading(false)
      });
  }

  useEffect(() => {
    refetch()
  }, []);

  const onClose = () => {
    navigate(-1);
  };

  const form = useForm({
    defaultValues: {
      serialNumber: '',
      manufacturer: '',
      model: '',
      hasEDM: true,
      description: '',
      memory: '',
      year: '',
      display: '',
      disk: '',
      company: "Knowit Ojectnet"
    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit")) {
        const computer: Computer = {
          serialNumber: values.serialNumber!,
          manufacturer: manufacturers!.find(manufacturer => manufacturer.id.toString() === values.manufacturer) as Manufacturer,
          model: (manufacturers!.find(manufacturer => manufacturer.id.toString() === values.manufacturer) as Manufacturer).models.find(model => model.id.toString() === values.model) as Model,
          description: values.description,
          memory: parseInt(values.memory!),
          year: parseInt(values.year!),
          display: parseInt(values.display!),
          disk: parseInt(values.disk!),
          rentStatus: null,
          id: 0,
          computerId: 0,
          hasEDM: values.hasEDM,
          deleted: new Date(),
          rentHistory: [],
          company: values.company
        }
        backend.computers.create(
          computer
        ).then((response) => {
          navigate(`/computers/${response.id}`, { replace: true });
          toast({
            duration: 4000,
            description: "Success: Created new PC 🤘",
            status: 'success',
          });
        }).catch((error) => {
          // TODO: Get a proper error message
          toast({
            duration: 4000,
            description: error.message,
            status: 'error',
          })
          console.log(error)
        });
      }
    }
  })

  const handleManufacturerChange = () => {
    const manufacturerId = form.getFieldValue('manufacturer');
    const selectedManufacturer = manufacturers!.find(manufacturer => manufacturer.id.toString() === manufacturerId);

    if (selectedManufacturer) {
      setManufacturer(selectedManufacturer);
    } else {
      setManufacturer(undefined);
    }
  };

  useEffect(() => {
    /* update computer based on imageSpecs */
    if (imageSpecs) {
      const manufacturerId = manufacturers!.find(manufacturer => manufacturer.name.includes(imageSpecs.manufacturer!))?.id.toString() || undefined;
      const modelId = (manufacturers!.find(manufacturer => manufacturer.name.includes(imageSpecs.manufacturer!)) as Manufacturer).models.find(model => model.name.toLowerCase().includes(imageSpecs.model!))?.id.toString() || undefined;

      if (imageSpecs.serial) {
        form.setFieldValue('serialNumber', imageSpecs.serial);
      }
      if (manufacturerId) {
        form.setFieldValue('manufacturer', manufacturerId);
      }
      if (modelId) {
        form.setFieldValue('model', modelId);
      }
      if (imageSpecs.hasEDM) {
        form.setFieldValue('hasEDM', true);
      }
      if (imageSpecs.data) {
        form.setFieldValue('description', imageSpecs.data);
      }
      if (imageSpecs.specs?.ram) {
        form.setFieldValue('memory', imageSpecs.specs?.ram);
      }
      if (imageSpecs.specs?.storage) {
        form.setFieldValue('disk', imageSpecs.specs?.storage);
      }
      if (imageSpecs.specs?.display) {
        form.setFieldValue('display', imageSpecs.specs?.display);
      }
    }

  }, [imageSpecs]);

  const Template = (type: string) => {
    setManufacturer(manufacturers!.find(manufacturer => manufacturer.name === type) as Manufacturer);
    form.setFieldValue('disk', '1024')
    form.setFieldValue('memory', "Apple" === type ? '36' : '32')
    form.setFieldValue('display', '16')
    form.setFieldValue('year', '2023')
    form.setFieldValue('manufacturer', manufacturers!.find(manufacturer => manufacturer.name === type)?.id.toString() || '');
    form.setFieldValue('model', (manufacturers!.find(manufacturer => manufacturer.name === type) as Manufacturer).models[0].id.toString());
    form.setFieldValue('hasEDM', true);
    form.setFieldValue('company', "Knowit Objectnet");
    form.validateAllFields("submit");
  };

  const FormFields = [
    (
      <ImageScanner callback={setImageSpecs} />
    ), (
      <form.Field
        name="serialNumber"
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Serial number is required'
            : undefined
        }}
        children={field => {
          return (
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Serialnumber</FormLabel>
              <Input variant='filled' required name={field.name} value={field.state.value} onBlur={field.handleBlur} onChange={(e) => field.handleChange(e.target.value)} placeholder="XXXXXXXXX" />
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="manufacturer"
        onChange={(value) => {
          handleManufacturerChange()
          return !value || value.length === 0
            ? 'Manufacturer is required'
            : undefined
        }}
        children={field => {
          return (
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Manufacturer</FormLabel>
              <Select variant='filled' required name={field.name} placeholder="Select manufacturer" value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                {manufacturers!.map(manufacturer => (
                  <option key={manufacturer.id} value={manufacturer.id}>
                    {manufacturer.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="model"
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Model is required'
            : undefined
        }}
        children={field => {
          return (
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Model</FormLabel>
              <Select variant='filled' required name={field.name} placeholder={"Select Model"} isDisabled={!manufacturer} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                {manufacturer?.models.map(model => (
                  <option key={model.id} value={model.id}>
                    {model.name}
                  </option>
                )) || []}
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="hasEDM"
        children={field => {
          return (
            <FormControl>
              <FormLabel>Has EDM</FormLabel>
              <Select variant='filled' name={field.name} value={field.state.value == true ? "true" : "false"} onChange={(e) => field.handleChange(e.target.value == "true")}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="company"
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Company</FormLabel>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option>Select Company</option>
                <option value="Knowit Objectnet">Knowit Objectnet</option>
                <option value="Knowit Experience Oslo">Knowit Experience Oslo</option>
                <option value="Knowit Solutions Norway">Knowit Solutions Norway</option>
                <option value="Knowit Impact">Knowit Impact</option>
                <option value="Knowit Dataess">Knowit Dataess</option>
                <option value="Knowit Decision">Knowit Decision</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="description"
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Desciption</FormLabel>
              <Textarea name="description" value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}></Textarea>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="disk"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Disk size is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Specs</FormLabel>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option value="">Select disk size</option>
                <option value="128">128GB</option>
                <option value="256">256GB</option>
                <option value="512">512GB</option>
                <option value="1024">1024GB</option>
                <option value="2048">2048GB</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="memory"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Memory size is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option value="">Select memory size</option>
                <option value="4">4GB</option>
                <option value="8">8GB</option>
                <option value="16">16GB</option>
                <option value="32">32GB</option>
                <option value="36">36GB</option>
                <option value="48">48GB</option>
                <option value="64">64GB</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="display"
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option value="">Select display size</option>
                <option value="13">13"</option>
                <option value="14">14"</option>
                <option value="15">15"</option>
                <option value="16">16"</option>
                <option value="17">17"</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="year"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Year is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option value="">Select year</option>
                {years.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>
          )
        }}
      />
    )
  ]

  const SubmitButton = () => {
    return (
      <Button colorScheme='blue' isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
        Create
      </Button>
    )
  }

  const Header = () => {
    return (
      <>
        <Heading size='xl' mt={5}>New Computer</Heading>
        <HStack mt={4}>
          <Button onClick={() => { Template("Apple") }}>Apple Template</Button>
          <Button onClick={() => { Template("Dell") }}>Dell Template</Button>
        </HStack>
      </>
    )
  }

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 30 }, (_, i) => currentYear - i);

  return (
    <FormModal
      isLoading={manufacturers ? true : false}
      headerElements={[<Header />]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton />}
      onClose={onClose}
    />
  )
}
