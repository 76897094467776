import { Button, FormLabel, Heading, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Manufacturer } from "../../models/manufacturer";
import { useForm } from '@tanstack/react-form'
import { Textarea, FormControl } from '@chakra-ui/react'
import { useFetch } from "../../contexts/FetchContext";
import { FormModal } from "../../components/Helpers/FormModal";
import { Keycard } from "../../models/keycard";

export const CreateKeycard = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [manufacturer, setManufacturer] = useState<Manufacturer>();

  const navigate = useNavigate()
  const { backend } = useFetch();

  const refetch = () => {
    setLoading(true)
    backend.manufacturers.get()
    .then((data) => {
      /* Må FIKSES */
      const keyCardManufacturer = data.find(manufacturer => manufacturer.name === "Keycard")
      if (keyCardManufacturer){
        setManufacturer(keyCardManufacturer)
      }else{
        onClose()
      }
      setLoading(false)
    });
  }

  useEffect(() => {
    refetch()
  }, []);

  const onClose = () => {
    navigate(-1);
  };

  const form = useForm({
    defaultValues: {
      manufacturer: '',
      model: '',
      description: '',
      type: '',
      cardId: '',
      pin: '',
    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit")) {
        const keycard: Keycard = {
          description: values.description,
          rentStatus: null,
          id: 0,
          cardId: values.cardId,
          type: values.type,
          pin: values.pin,
          deleted: new Date(),
          rentHistory: [],
        }
        backend.keycards.create(
          keycard
        ).then((response) => {
          onClose();
        }).catch((error) => {
          console.log(error)
        });
      }
    }
  })

  const FormFields = [
    (
      <form.Field
        name="cardId"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Card ID is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Card ID</FormLabel>
              <Input
                variant='filled'
                type="number"
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                placeholder="Card ID"
                required

              />
            </FormControl>
          )
        }}
      />
    ),
    (
      <form.Field
        name="pin"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Pin is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Pin</FormLabel>
              <Input
                variant='filled'
                required
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                placeholder="Pin"
              />
            </FormControl>
          )
        }}
      />

    ), (
      <form.Field
        name="type"
        children={field=> {
          return (
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Input
                variant='filled'
                required
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                placeholder='Type'
              />
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="description"
        children={field => {
          return (
            <FormControl>
              <FormLabel>Desciption</FormLabel>
              <Textarea
                name="description"
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            </FormControl>
          )
        }}
      />
    )
  ]

  const SubmitButton = () => {
    return(
      <Button colorScheme='blue' isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
        Create
      </Button>
    )
  }

  const Header = () => {
    return (
      <>
        <Heading size='xl' mt={5}>New Keycard</Heading>
      </>
    )
  }

  return (
    <FormModal
      isLoading={manufacturer ? true : false}
      headerElements={[<Header/>]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton/>}
      onClose={onClose}
    />
  )
}
