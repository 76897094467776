import { Badge, Button, HStack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "../contexts/FetchContext";
import { useEffect, useState } from "react";
import { LicenseQuery, licenseStatusEnum } from "../models/newLicenses";
import { HubConnectionBuilder } from "@microsoft/signalr";

export const LicenseButton = () => {

  const [licenseActions, setLicenseActions] = useState<number>(0)

  const location = useLocation();
  const navigate = useNavigate();
  const { backend } = useFetch();

  useEffect(() => {
    start();
  }, []);

  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env["REACT_APP_EQUIPIT_BACKEND"]}notificationhub`)
    .withAutomaticReconnect()
    .build();

  async function start() {
    try {
      await connection.start();
      console.log("SignalR Connected.");
    } catch (err) {
      console.log(err);
      setTimeout(() => start(), 5000);
    }
  }

  connection.on("UpdateNumLicenses", (message) => {
    setLicenseActions(message)
  });

  useEffect(() => {

    const licenseQuery: LicenseQuery = {
      LicenseStatuses: [licenseStatusEnum.DELETED_IN_AD, licenseStatusEnum.NEW_IN_AD],
    }
    backend.licenses.getAll(licenseQuery).then((licenses) => {
      setLicenseActions(licenses.length)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <Button onClick={() => navigate('/licenses')} isActive={decodeURI(location.pathname).startsWith('/licenses')} variant='navButton'>
      <HStack width={'fill-available'} justify={'space-between'}>
        <div>Licenses</div>
        {licenseActions > 0 && <Badge colorScheme='red' variant='solid'>{licenseActions}</Badge>}
      </HStack>
    </Button>
  )
}
