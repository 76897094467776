import { AxiosResponse } from "axios";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { Item } from "../../models/item";
import { ItemCollection } from "../../models/itemCollection";

export const itemAPI = (API: APIType, url: string) => {

  const URL = `${url}Item/`;

  const options = {
    getById: (id: number) => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${URL}`,
        param: {
          id: id
        }
      }
      return API(APIParams).then((response: AxiosResponse) => {
        return response.data as Item;
      }).catch((error: any) => {
        console.log(error);
        return undefined;
      })
    },
    getByEmail: async (email: string) => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${URL}${email}`,
      }
      try {
        const response = await API(APIParams);
        return response.data as ItemCollection;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    softDelete: (id: number) => {
      const APIParams = {
        method: Method.delete,
        needToken: true,
        URL: `${URL}SoftDelete/${id}`,
      }
      return API(APIParams)
    },
    restore: (id: number) => {
      const APIParams = {
        method: Method.post,
        needToken: true,
        URL: `${URL}Restore/${id}`,
      }
      return API(APIParams)
    },
    rent: (itemID: number, name: string,email: string, company: string, status: number, note: string) => {
      const APIParams: APIParams = {
        method: Method.post,
        needToken: true,
        URL: `${URL}Rent/${itemID}`,
        body: {
          name: name,
          email: email,
          company: company,
          status: status,
          note: note
        }
      }
      return API(APIParams)
    },
    return: (id: number) => {
      const APIParams = {
        method: Method.post,
        needToken: true,
        URL: `${URL}Return/${id}`,
      }
      return API(APIParams)
    }
  }
  return options;
}
