import { FunctionComponent, useContext } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { UserContext } from './auth/Auth';

export const App: FunctionComponent = (props) => {

  const user = useContext(UserContext);
  const router = createBrowserRouter(AppRoutes());

  return (
    <>
      <RouterProvider router={router}/>
    </>
  )
}