import { AxiosResponse } from "axios";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { License, LicenseGroup } from "../../models/newLicenses";

export const licenses = (API: APIType, URL: string) => {
  const licenseURL = URL + 'license';

  const options = {
    getAll: async (params?: any) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: licenseURL,
        param: params
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {
        return response.data as License[];
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      });
    },
    approve: async (licenseId: number) => {
      const APIParams = {
        method: Method.put,
        needToken: true,
        URL: licenseURL + '/approve/' + licenseId
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as LicenseGroup;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    delete: async (licenseId: number) => {
      const APIParams = {
        method: Method.put,
        needToken: true,
        URL: licenseURL + '/delete/' + licenseId
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as LicenseGroup;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    }
  }
  return options;
}
