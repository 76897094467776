import { AxiosResponse } from "axios";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { LicenseUser, LicenseUserTable } from "../../models/licenses";

export const licenseAPI = (API: APIType) => {
  const licenseURL: string = `${process.env["REACT_APP_LICENSEIT_BACKEND"]}`;
  const options = {
    invalidlicenses: async () => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${licenseURL}invalidlicenses`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      });
    },
    licenses: async () => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${licenseURL}licenses`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    allUserLicenses: async () => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${licenseURL}allUserLicenses`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    allLicenses: async () => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${licenseURL}allLicenses`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    getUserLicenses: async (email: string) => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${licenseURL}getUserLicenses/${email}`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as LicenseUserTable[];
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    getTable: async (table: string) => {
      const APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${licenseURL}${table}`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data as LicenseUser[];
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    addLicense: async (table: string, id: string) => {
      const APIParams = {
        method: Method.put,
        needToken: true,
        URL: `${licenseURL}${table}/addlicense/${id}`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    removeLicense: async (table: string, id: string) => {
      const APIParams = {
        method: Method.put,
        needToken: true,
        URL: `${licenseURL}${table}/removelicense/${id}`,
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    createLicense: async (displayName: string, licenseName: string) => {
      const APIParams = {
        method: Method.post,
        needToken: true,
        URL: `${licenseURL}createLicense`,
        body: {displayName: displayName, licenseName: licenseName}
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    invalidateLicense: async (license_id: string) => {
      const APIParams = {
        method: Method.post,
        needToken: true,
        URL: `${licenseURL}invalidatelicense`,
        body: JSON.stringify({ license_id: license_id})
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    },
    validateLicense: async (license_id: string) => {
      const APIParams = {
        method: Method.post,
        needToken: true,
        URL: `${licenseURL}validatelicense`,
        body: JSON.stringify({ license_id: license_id})
      }
      return await API(APIParams)
      .then((response: AxiosResponse) => {;
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw new Error(error);
      })
    }
  }
  return options;
}
