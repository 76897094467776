import { Center, HStack, Heading, Modal, ModalContent, Spinner, Stack, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Text } from '@chakra-ui/react'
import { useFetch } from "../../contexts/FetchContext";
import { Keycard, keycardToString } from "../../models/keycard";
import { ItemModal } from "../../components/ItemModal";

export const KeycardModal = () => {
  const [keyCard, setKeyCard] = useState<Keycard>()
  const navigate = useNavigate();
  const { backend } = useFetch();
  const { id } = useParams();
  const location = useLocation();

  const onClose = () => {
    navigate("/keycards" + location.search);
  };

  /* Get keyCard */
  useEffect(() => {
    if (id) {
      refetch()
    } else {
      console.debug('Could not find keycard id')
      onClose()
    }
  }, [id]);

  const refetch = () => {
    console.log(`Fetching keycard ${id}`);
    backend.keycards.getById(parseInt(id!))
      .then((data) => {
        setKeyCard(data);
      }).catch((e) => {
        console.error(e)
        onClose()
      })
  }

  return (
    <Modal isCentered={true} isOpen={true} onClose={onClose} size='3xl'>
      {keyCard ?
        <ItemModal headerLine2={keyCard.type} headerLine1={keycardToString(keyCard)} item={keyCard} onClose={onClose} >
          <VStack spacing={8} align='flex-start'>
            <Stack direction='column' flex={1} align='flex-start' spacing={4}>
              <Heading size='md'>Specifications</Heading>
              <HStack align='flex-start' wrap='wrap' gap={10}>
                <VStack align='flex-start' spacing={0}>
                  <Heading size='sm'>PIN CODE</Heading>
                  <Text size='sm'>{keyCard.pin}</Text>
                </VStack>
              </HStack>
            </Stack>
            {keyCard.description &&
              <VStack flex={1} align='flex-start' spacing={2} whiteSpace='pre-line'>
                <Heading size='md'>Description</Heading>
                <Text size='sm'>{keyCard.description}</Text>
              </VStack>
            }
            {keyCard.rentStatus?.note &&
              <VStack flex={1} align='flex-start' spacing={2}>
                <Heading size='md'>Notes</Heading>
                <Text size='sm' whiteSpace='pre-line'>
                  {keyCard.rentStatus.note}
                </Text>
              </VStack>
            }
          </VStack>
        </ItemModal>
        :
        <ModalContent bg="transparend" shadow="none">
          <Center>
            <Spinner size='lg' />
          </Center>
        </ModalContent>
      }
    </Modal>
  )
}
