import { useContext, useEffect, useState } from 'react';
import { 
  Heading,
  useColorMode, 
  useColorModeValue,
  Stack,
  Grid,
  GridItem,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  IconButton,
  Avatar,
  useMediaQuery,
  HStack,
} from '@chakra-ui/react'
import {SunIcon, LockIcon, MoonIcon, SettingsIcon, } from '@chakra-ui/icons'
import { useLocation } from 'react-router-dom';
import { UserContext } from '../auth/Auth';
import { useMsal } from '@azure/msal-react';
import { AppContext } from '../contexts/AppContext';

export const Header = () => {

  const {instance} = useMsal();

  const app = useContext(AppContext)

  const { toggleColorMode } = useColorMode()

  const [pageTitle, setPageTitle] = useState<string>('Title')

  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')

  const user = useContext(UserContext);

  useEffect(() => {
    setPageTitle(app.title)
    document.title = app.title
  }, [app]);

  const colorMode = useColorMode()

  const logout = () => {
    instance.logoutRedirect({ account: instance.getActiveAccount() });
  }

  const bg = useColorModeValue('white', 'gray.900')

  const colorModeIcon = () => {
    if (colorMode.colorMode == 'dark'){
      return <SunIcon/>
    }
    return <MoonIcon/>
  }

  return (
    <Grid
      h='100%'
      pl={8}
      pr={4}
      pt={16} //65px
      pb={8} //30px
      gap={4}
      templateAreas={`
        "title settings"`}
      gridTemplateRows={'1fr'}
      gridTemplateColumns={'1fr auto'}
      alignItems='center'
    >
      <GridItem area={'title'}>
        <Heading>{pageTitle}</Heading>
      </GridItem>
      <GridItem area={'settings'}>
        <Stack direction='row' alignItems='center' borderRadius={6} bg={bg} shadow='md' p={3}>
          {isLargerThan1280 && (
            <Heading size='xs'>{user?.name}</Heading>
          )}
          <Avatar name={user?.name} size='sm' src='' />
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<SettingsIcon />}
              variant='ghost'
            />
            <MenuList>
              {!isLargerThan1280 && (
                <HStack
                  p={4}
                  w={'100%'}
                  alignItems={'center'}
                  justify={'space-between'}
                >
                    <Heading size='sm'>{user?.name}</Heading>
                    <Avatar name={user?.name} size='md' src='' />
                </HStack>
              )}
              <MenuItem icon={colorModeIcon()} onClick={toggleColorMode}>
                {colorMode.colorMode == 'dark' ? "Switch to Light Mode" : "Switch to Dark Mode"}
              </MenuItem>
              <MenuItem icon={<SettingsIcon />}>
                Settings
              </MenuItem>
              <MenuItem onClick={logout} icon={<LockIcon />}>
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </GridItem>
    </Grid>
  )
}

export default Header;