import { APIParams, APIType, Method } from "../../contexts/FetchContext";
import { AxiosResponse } from "axios";
import { EquipitUser } from "../../models/user";

export const permissionAPI = (API: APIType, URL: string) => {
    const userURL = URL + 'Permission';

    const options = {
        getAllPermissions: async (params?: any) => {
            const APIParams: APIParams = {
                method: Method.get,
                needToken: true,
                URL: userURL,
            }
            return API(APIParams).then((response: AxiosResponse) => {
                return response.data;
            }).catch((error: any) => {
                console.error(error);
                return [];
            })
        },
        updateRoleName: async (roleId: string, roleName: string) => {
            const APIParams: APIParams = {
                method: Method.post,
                needToken: true,
                URL: `${userURL}/updateRoleName`,
                param: { roleId, roleName },
            }
            return API(APIParams).then((response: AxiosResponse) => {
                return response.data;
            }).catch((error: any) => {
                console.log(error);
                return [];
            })
        },
        assignPermissionToRole: async (permissionId: number, roleId: string) => {
            const APIParams: APIParams = {
                method: Method.post,
                needToken: true,
                URL: `${userURL}/assign`,
                param: { permissionId, roleId },
            }
            return API(APIParams).then((response: AxiosResponse) => {
                return response.data;
            }).catch((error: any) => {
                console.log(error);
                return [];
            })
        },
        removePermissionFromRole: async (permissionId: number, roleId: string) => {
            const APIParams: APIParams = {
                method: Method.delete,
                needToken: true,
                URL: `${userURL}/remove?permissionId=${permissionId}&roleId=${roleId}`,
            }
            return API(APIParams).then((response: AxiosResponse) => {
                return response.data;
            }).catch((error: any) => {
                console.log(error);
                return [];
            })
        },
        getPermissionsForRole: async (roleId: string) => {
            const APIParams: APIParams = {
                method: Method.get,
                needToken: true,
                URL: `${userURL}/permissionRole/${roleId}`,
            }
            return API(APIParams).then((response: AxiosResponse) => {
                return response.data;
            }).catch((error: any) => {
                console.log(error);
                return [];
            })
        },
        assignCompanyFromRole: async (roleId: string, companyName: string) => {
            const APIParams: APIParams = {
                method: Method.post,
                needToken: true,
                URL: `${userURL}/assignCompany`,
                param: { roleId, companyName },
            }
            return API(APIParams).then((response: AxiosResponse) => {
                return response.data;
            }).catch((error: any) => {
                console.log(error);
                return [];
            })
        },
    };

    return options;
};