import { ReactNode, createContext, useContext } from "react";
import axios, { AxiosResponse } from 'axios';
import { fromAPI, fromAPIType } from "../api";

type FetchContextProps = {
  children: ReactNode
  fetchToken: () => Promise<string>
}

type Fetch = (resource: string, params? : any) => Promise<Response>

export type APIType = ({method, needToken, URL, param, body}: APIParams) => Promise<AxiosResponse<any, any>>

export enum Method {
  get,
  put,
  post,
  delete
}

export type APIParams = {
  method: Method;
  needToken: boolean;
  URL: string;
  param?: any;
  body?: any;
}

const FetchContext = createContext<fromAPIType>(undefined!);

const useAPI = () => {
  return useContext(FetchContext)
}

export const APIContextProvider = ({children, fetchToken}: FetchContextProps) => {

  const headers = async (needToken: boolean) => {
    if (needToken) {
      const token = await fetchToken()
      return ({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      })
    }
    return ({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
  }

  const API = async ({method, needToken, URL, param, body}: APIParams) => {
    switch (method) {
      case Method.get:
        return axios.get(URL, {
          headers: await headers(needToken),
          params: param,
          paramsSerializer: {
            indexes: null
          }
        })
      case Method.post:
        return axios.post(URL, body, {
          headers: await headers(needToken),
          params: param,
          paramsSerializer: {
            indexes: null
          }
        })
      case Method.delete:
        return axios.delete(URL, {
          headers: await headers(needToken),
          data: param,
          paramsSerializer: {
            indexes: null
          }
        })
      case Method.put:
        return axios.put(URL, body, {
          headers: await headers(needToken),
          params: param,
          paramsSerializer: {
            indexes: null
          }
        })
      default:
        return Promise.reject('Method not implemented');
    }
  }

  return (
    <FetchContext.Provider value={fromAPI(API)}>
      {children}
    </FetchContext.Provider>
  )
}

export { useAPI as useFetch, FetchContext }
